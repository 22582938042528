// Packages
import tw, { styled } from "twin.macro";

export const Container = styled.footer(() => [
  ``,
  tw`absolute top-10  z-50 right-8 lg:right-24`,
]);

export const Select = styled.select(() => [
  ``,
  tw`bg-none bg-transparent text-white`,
]);
