// Packages
import { useContext, useEffect } from "react";

// Language
import { Language } from "../../Language/Language";

// Elements
import {
  Container,
  Card,
  CardBodyGrouped,
  LocationInner,
  LocationOuter,
  CardFooter,
  Title,
  Address,
  Detail,
  Instruction,
  Button,
} from "./Results.elements";

import { IconCuisine, IconBell } from "./Results.icons";

import { setCardWidth } from "./Listing.helpers";

export default function PocketCard() {

  const {
    pocketCard: { title, description, cuisines, instruction, button },
  } = useContext(Language);

  const href = "https://pocket-concierge.jp/?extlink=va-jp-ICS-blobal_dining";

  useEffect(() => {
    setCardWidth();
  }, []);

  return (
    <Container>
      <Card>
        <CardBodyGrouped>

          <Title>{title}</Title>

          <LocationOuter>
            <LocationInner>

              <Address dangerouslySetInnerHTML={{ __html: description }}></Address>

              <Detail>
                <IconCuisine />
                {cuisines}
              </Detail>

            </LocationInner>
          </LocationOuter>

        </CardBodyGrouped>
        <CardFooter>

          <Instruction>
            <IconBell />
            <span>{instruction}</span>
          </Instruction>

          <Button
            rel="noopener noreferrer"
            href={href}
          >
            {button}
          </Button>

        </CardFooter>
      </Card>
    </Container>
  );
}
