import tw, { styled } from "twin.macro";

import PlatBanner from "../../_images/plat_banner.png";
import PlatBannerCrop from "../../_images/plat_banner_crop.jpg";
import CentBanner from "../../_images/cent_banner.svg";
import Wave from "../../_images/wave.jpg";

export const PlatinumBanner = styled.div(({ paired }) => {
  const bgPlat = `background: url("${ PlatBanner }") 0% 0%;`;
  const bgPlatPaired = `background: url("${ PlatBannerCrop }") 50% 50% / cover no-repeat;`;
  const bgPlatOverlay
    = `&:before { content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: rgb(0,0,0,0.6); z-index: -1; }`;

  return [
    'margin-left: -2px; margin-right: -2px; padding: 40px 24px;',
    tw`flex flex-col justify-end relative z-0 overflow-hidden mt-6 lg:mt-0`,
    ...paired ? [bgPlatOverlay, bgPlatPaired, tw`flex-1`] : [bgPlat]
  ];
});

export const CenturionBanner = styled.div(({ paired }) => [
  'margin-left: -2px; margin-right: -2px; padding: 40px 24px;',
  'min-height: 300px; @media screen and (min-width: 1024px) { min-height: 328px; }',
  `background: #000 url("${ CentBanner }") 100% 0% / contain no-repeat;`,
  `&:before { content: ''; position: absolute; left: 0; top: 0; width: ${ paired ? '72%' : '95%' }; height: 100%; background: rgb(0,0,0);
  background: linear-gradient(270deg, rgba(0,0,0,0.00) 0%, #000000 100%); z-index: -1; }`,
  paired ? tw`flex-1` : ``,
  tw`flex flex-col justify-end relative z-0 overflow-hidden mt-6 lg:mt-0`,
  // Market overrides:
  `[data-locale="de-at"] & { background-image: url("${ Wave }"); background-position: 50% 50%; background-size: cover;}`,
  `[data-locale="de-at"] &:before { width: 100%; height: 100%; background: rgb(0,0,0,0.6); }");`,
]);
