const lang = {
  intro: {
    title: "Explore dining across the country",
    paragraph:
      "<p>We’ve curated a selection of restaurants that were ranked on Canada’s 100 Best Restaurants over the past few years, for you to take advantage of your $200 Annual Dining Credit.<BR><BR>Enrollment is required via Amex Offers in your Online Services account or the American Express® App.</p>",
    tcLink:
      '<a href="%PATH%EN-CA-T&Cs.pdf" target="_blank" rel="noopener">View full benefit details and T&Cs</a>',
  },
  filters: {
    country: "Country:",
    state: "State:",
    stateDefault: "All states",
    city: "City:",
    cityDefault: "All cities",
    cuisine: "Cuisine:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants not available for this selection",
    viewmap: "View on Google Maps",
    makebooking: "To make a booking please visit:",
    makebookingTel: "To make a booking please call:",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Delivery/Take-Away available",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisines",
    close: "close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only restaurants accepted prepaid are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>* This restaurant is located within a hotel. If the restaurant charge does not appear on your statement as a separate itemized transaction (for example, if you charged the meal to your room as part of your hotel stay), it may not be eligible for the $200 Annual Dining Credit. To qualify, the purchase must be charged to your Card directly by the restaurant, and the restaurant must appear on your statement as the merchant (not the hotel).</p><br/><p>Only Basic Platinum Cardmembers who have received this offer are eligible. Enrolment required. Full offer details can be found in the terms and conditions when registering for the offer. The list of eligible restaurants is updated from time to time and may not be 100% accurate.</p></br><p>Please note that by visiting an eligible restaurant’s website, you are navigating away from American Express and as such we are not responsible for any content on any site not owned and operated by American Express.</p><p>Copyright © 2023 American Express Company</p>",
  },
  platinumBanner: {
    title: "Explore all your benefits",
    text: "Redefine what's possible for the things you love with benefits, experiences, and rewards that enhance your journey, with the Platinum Card® from American Express.",
    button: "Explore now",
  },
};

export default lang;
