// Packages
import { Suspense, useContext, useEffect, useState /* useMemo */ } from "react";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useParams } from "react-router-dom";

// Store
import {
  countryId,
  cityId,
  cuisineId,
  countryData,
  countryProps,
  cityData,
  cuisineData,
  filterHalal,
  merchantsData,
} from "../../Store/Store";

// Language
// import { languages } from "../../Language/Language";

// Context
import { Language } from "../../Language/Language";

// Helpers
import { buildSelect } from "./Filters.helpers";

// Elements
import {
  Container,
  Filter,
  Label,
  Select,
  Checkbox,
  CheckboxContents,
} from "./Filters.elements";

function FiltersSuspense({ filters }) {
  return (
    <>
      <Filter>
        <Label>{filters.country}</Label>
        <Select disabled />
      </Filter>
      <Filter>
        <Label>{filters.city}</Label>
        <Select disabled />
      </Filter>
      <Filter>
        <Label>{filters.cuisine}</Label>
        <Select disabled />
      </Filter>
    </>
  );
}

function FilterStrings({ name, label, all, atom, selector }) {
  const { locale } = useParams();

  const setSelected = useSetRecoilState(atom);
  const items = useRecoilValue(selector);
  const props = useRecoilValue(countryProps);

  function onChangeHandler({ target: { value } }) {
    setSelected(value);
  }

  // const memoizedSelect = useMemo(
  //   () =>
  //     buildSelect({
  //       name,
  //       label,
  //       all,
  //       items: items ? items : [],
  //       countryId: props.key,
  //       onChangeHandler,
  //       hideSelect: (name === 'city' && !props.showCityDropdown) || false,
  //       // lang: locale.split("-")[0].toLowerCase(),
  //       market: locale.replace('-', '_'),
  //     }),
  //   [items]
  // );

  // if (!items) return null;

  // return memoizedSelect;

  const selectElement = buildSelect({
    name,
    label,
    all,
    items: items ? items : [],
    countryId: props.key,
    onChangeHandler,
    hideSelect: (name === "city" && !props.showCityDropdown) || false,
    // lang: locale.split("-")[0].toLowerCase(),
    market: locale.replace("-", "_"),
  });

  if (!items) return null;

  return selectElement;
}

function FilterCountry({ label, showJapan }) {
  const { locale } = useParams();

  const countries = useRecoilValue(countryData);
  const [id, setCountryId] = useRecoilState(countryId);
  const [hideCountrySelect, setHideCountrySelect] = useState(false);
  const props = useRecoilValue(countryProps);
  const resetCity = useResetRecoilState(cityId);
  const resetCuisine = useResetRecoilState(cuisineId);
  const localeCountry = locale.split("-")[1].toUpperCase();

  function onChangeHandler({ target }) {
    setCountryId(target.value);
    resetCity();
    resetCuisine();
  }

  function translate(item) {
    // Fall back to "en" value...
    const market = locale.replace("-", "_");
    const f = item.translations[market] || item.translations["en"];
    return f.title;
  }

  useEffect(() => {
    const codes = countries
      .map((zone) => zone.countries.map((c) => c.key))
      .flat();

    const split = locale.split("-");

    const validLocale = split.length > 1 ? split[1].toUpperCase() : "GB";

    const id = codes.includes(validLocale) ? validLocale : "GB";

    setCountryId(id);
  }, [countries]);

  useEffect(() => {
    // console.log(props.key);
    // if(props.key === "JP") { // TEMP: todo – set canBrowseOtherMarkets to true for JP
    //   setHideCountrySelect(false);
    //   return;
    // }
    if (props.key && props.key === id) {
      setHideCountrySelect(
        !props.canBrowseOtherMarkets && localeCountry === id
      );
    }
  }, [props]);

  return (
    <Filter style={{ display: hideCountrySelect ? "none" : "" }}>
      <Label>{label}</Label>
      {id && (
        <Select
          name="country"
          id="country"
          value={id}
          onChange={onChangeHandler}
        >
          {countries.map((region) => (
            <optgroup key={region.title} label={translate(region)}>
              {region.countries.map(
                (country) =>
                  country.isActive &&
                  (country.key === id || !hideCountrySelect) &&
                  (country.isVisibleToOtherMarkets ||
                    country.key === localeCountry) && (
                    // ((country.key !== 'JP') || (country.key === 'JP' && showJapan)) &&
                    <option key={country.key} value={country.key}>
                      {translate(country)}
                    </option>
                  )
              )}
            </optgroup>
          ))}
        </Select>
      )}
    </Filter>
  );
}

export default function Filters() {
  const { locale } = useParams();

  const { filters } = useContext(Language);
  const id = useRecoilValue(countryId);
  const [showCuisineFilter, setShowCuisineFilter] = useState(true);
  const [showJapanInDropdown] = useState(true);
  const [regionType, setRegionType] = useState("city");
  const [regionDefault, setRegionDefault] = useState("cityDefault");
  const merchants = useRecoilValue(merchantsData);
  const [filterHalalMerchants, setFilterHalalMerchants] =
    useRecoilState(filterHalal);
  const areThereHalalMerchants = hasHalalMerchants(merchants);

  function enableHalalFilter() {
    filterHalalMerchants
      ? setFilterHalalMerchants(false)
      : setFilterHalalMerchants(true);
  }

  const twoFilters =
    locale === "fr-fr" || locale === "fr-ca" || locale === "en-ca";

  function hasHalalMerchants(merchants) {
    if (merchants) {
      return merchants.some((merchant) => {
        return merchant.businessData.isHalal;
      });
    } else {
      return false;
    }
  }
  // const {
  //   params: { locale },
  // } = useMatch();

  useEffect(() => {
    // Hard-coded override for JP market (could move to API like showCityDropdown)
    setShowCuisineFilter(id !== "JP");
    // Hard-coded override for AU cities
    setRegionType(id === "AU" ? "state" : "city");
    setRegionDefault(id === "AU" ? "stateDefault" : "cityDefault");
    // Hard-coded override to hide JS from some markets
    // setShowJapanInDropdown(['en-au', 'zh-hk', 'en-hk'].includes(locale));
  }, [id]);

  return (
    <>
      <Container id="filters">
        <Suspense fallback={<FiltersSuspense filters={filters} />}>
          <FilterCountry
            label={filters.country}
            showJapan={showJapanInDropdown}
          />
          <FilterStrings
            name="city"
            atom={cityId}
            selector={cityData}
            label={filters[regionType]}
            all={filters[regionDefault]}
          />
          {showCuisineFilter && (
            <FilterStrings
              name="cuisine"
              atom={cuisineId}
              selector={cuisineData}
              label={filters.cuisine}
              all={filters.cuisineDefault}
            />
          )}
          {twoFilters && areThereHalalMerchants && (
            <Checkbox>
              <CheckboxContents>
                <input
                  type="checkbox"
                  id="halal"
                  name="halal"
                  onClick={enableHalalFilter}
                />
              </CheckboxContents>
              <CheckboxContents>
                {locale === "en-ca" ? (
                  <label for="halal"> Halal </label>
                ) : (
                  <label for="halal"> Repas halal uniquement</label>
                )}
              </CheckboxContents>
            </Checkbox>
          )}
        </Suspense>
      </Container>
    </>
  );
}
