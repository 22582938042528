// Packages
import { Fragment, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

// Components
import { Language, languages } from "../Language/Language";

// Pages
import Listing from "../Pages/Listing/Listing";
import NotFound from "../Pages/NotFound/NotFound";
import Interstitial from "../Pages/Interstitial/Interstitial";

// Helpers
import { getDefaultLanguage } from "./Router.helpers.js";

function setDigitalData(key) {
  // This needs to be called before we set the lang attribute in the DOM!
  if (!window.digitalData) {
    return;
  }

  const conf = {
    pageName: {
      dining: "DiningOffer",
      social: "",
    },
    businessUnit: {
      dining: "Loy",
      social: "",
    },
    primaryCategory: {
      dining: "Benefits",
      social: "",
    },
    subCategory1: {
      dining: "VIPremium",
      social: "",
    },
  }

  window.digitalData.page.pageInfo.pageName = conf.pageName[key];
  window.digitalData.page.category.businessUnit = conf.businessUnit[key];
  window.digitalData.page.category.primaryCategory = conf.primaryCategory[key];
  window.digitalData.page.category.subCategory1 = conf.subCategory1[key];

}

function RouteWatcher({ onLocaleChange, handleNotFound }) {
  const { locale } = useParams();

  if(!document.documentElement.getAttribute('lang')){
    setDigitalData("dining");
    document.documentElement.setAttribute('lang', locale);
  }

  useEffect(() => {
    if (!locale) {
      return;
    }
    // Replace en-gb failsafe: check if locale in languages AND country showLandingPage
    if (locale && locale in languages && locale.includes("-")) {
      // TODO: extract countries outside hook so only loaded once...?
      async function fetchMyAPI() {
        let res = await fetch(`${process.env.REACT_APP_API_PATH}/api/countries`);
        return await res.json();
      }
      fetchMyAPI().then((r) => {
        if (r && r.length) {
          let props = null;
          const id = locale.split("-")[1].toUpperCase();
          r.forEach(region => {
            const country = region.countries.find(c => c.key === id);
            if (country && !props) {
              props = country;
            }
          });
          if (props.showLandingPage) {
            onLocaleChange({ locale: locale });
          } else {
            handleNotFound();
          }
        }
      });
    } else {
      handleNotFound();
    }
  }, [locale]);

  return null;
}

// Router
export default function Router() {
  const [lang, setLang] = useState(getDefaultLanguage());
  const [isNotFound, setIsNotFound] = useState(false);

  function onLocaleChange({ locale }) {
    setLang(locale);
  }

  function handleNotFound(){
    setIsNotFound(true);
  }

  function platinumCampaignReady() {
    setDigitalData("social");
  }

  function BenefitLanding() {
    return (
        isNotFound
        ? <NotFound />
        : (
          <Suspense fallback={<div></div>}>
            <Fragment>
              <RouteWatcher onLocaleChange={onLocaleChange} handleNotFound={handleNotFound} />
              <Listing />
            </Fragment>
          </Suspense>
        )
    );
  }

  return (
    <Language.Provider value={languages[lang]}>
      <BrowserRouter>
        <Routes>
          <Route path="/de-de/campaigns/platinum-retail-benefit/" element={<Interstitial onReady={platinumCampaignReady} />} />
          <Route path="/:locale/benefits/diningbenefit/" element={ <BenefitLanding /> } />
          <Route path="/:locale/avantages/avantagepourrestaurants/" element={ <BenefitLanding /> } />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Language.Provider>
  );
}
