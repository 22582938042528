const lang = {
  intro: {
    title: "Explore el mundo de la alta gastronomía",
    paragraph:
      "<p>A medida que celebramos la reapertura de los restaurantes, American Express te acompaña para que disfrutes de tu restaurante favorito en Argentina o alrededor del mundo. Válido desde el 08 de octubre hasta el 31 de diciembre de 2021, ya sea para ir disfrutar de una experiencia al aire libre en un restaurante local o descubrir nuevos restaurantes en el extranjero. Hemos seleccionado más de 2000+ restaurantes en 20 países para que disfrute de esta experiencia.</p>",
    tcLink:
      '<a href="%PATH%#" target="_blank" rel="noopener">View full dining benefit details and T&Cs</a>',
    hotelRestaurant:
      "*  Tenga en cuenta que si se aloja en un hotel, este crédito se aplicará únicamente al abonar la cuenta del restaurante directamente con el restaurante, independientemente del pago total del hotel.",
  },
  filters: {
    country: "País:",
    state: "State:",
    stateDefault: "All states",
    city: "Ciudad:",
    cityDefault: "All cities",
    cuisine: "Tipo de gastronomía:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurantes no disponibles para esta selección",
    viewmap: "Ver en el mapa",
    makebooking: "Para hacer una reserva, visite:",
    makebookingTel: "Para hacer una reserva llame al:",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Entrega / comida para llevar disponible",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisines",
    close: "close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only restaurants accepted prepaid are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>Requiere inscripción y es aplicable solo para titulares de las tarjetas Centurion y Platinum.</p><p>Encontrará más detalles en los términos y condiciones al inscribirse al beneficio.</p><p>Para obtener detalles completos del beneficio, consulte el correo electrónico que recibió.</p><p>Copyright © 2023 American Express Company</p>",
  },
};

export default lang;
