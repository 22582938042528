// Packages
import tw, { styled } from "twin.macro";
import Flourish from "../../_images/background-flourish.svg";
import Location from "../../_images/background-pin.svg";

export const SuspenseContainer = styled.div(() => [
  `max-width:1280px;`,
  `> div > div { margin-bottom: 1rem; }`,
  // tw`flex flex-wrap pt-0 pl-2 pr-2 gap-4`
  tw`flex flex-wrap`,
]);

export const Container = styled.div(() => [
  `min-height:100px;`,
  // tw`grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`,
  tw`mx-auto md:max-w-3xl lg:max-w-5xl`,
]);

export const ErrorMsg = styled.div(() => [
  ``,
  tw`flex justify-center text-white`,
]);

export const Card = styled.div(({ isGhost, location }) => [
  isGhost
    ? `* {opacity:0; pointer-events:none;}`
    : `height:100%; transition: transform 0.2s ease-in-out; &:hover{transform: scale(1.05);}`,
  location
    ? `background-image: url("${Location}");  background-repeat: no-repeat; background-size: cover;`
    : `background-image: url("${Flourish}"); background-repeat: no-repeat; background-size: cover;`,
  "@media screen and (min-width: 768px) { width: var(--card-width); }",
  // 'width: var(--card-width);',
  `min-height: 320px;`,
  tw`flex flex-col text-copy bg-white p-4 pt-3 border border-gray-300 shadow-sm`,
]);

export const CardBody = styled.div(() => [
  `
  `,
  tw`flex-1 text-center relative`,
]);

export const CardFooter = styled.div(() => [``, tw`mt-4 text-center`]);

export const Title = styled.div(() => [
  `letter-spacing:-0.41px; justify-content:center;`,
  `:first-line {padding-right:80px}`,
  tw`flex  text-cardtitle mt-2 mb-4 font-serif leading-snug row-auto`,
]);

export const Address = styled.div(() => [``, tw`text-sm leading-normal mb-4`]);

export const Detail = styled.div(() => [
  `a{color:#006FCF}+ div {margin-top:0.6875rem;}`,
  `
    a {
      &:focus {
        outline: 1px dashed currentColor;
        outline-offset: 3px;
      }
    }
  `,
  tw`text-sm leading-snug flex content-center justify-center`,
]);

export const Instruction = styled.div(({ marginBottom, weight }) => [
  `font-weight:${weight ? weight : 400};margin-bottom:${
    marginBottom ? marginBottom : 0
  }px;`,
  "span { flex: 1 auto; text-align: center;}",
  "span.has-icon { margin-left: -1.625rem; max-width: calc(100% - 18px); }",
  `svg { align-self: baseline }`,
  tw` flex justify-between`,
]);

export const DetailSVG = styled.svg(() => [
  `min-width:16px;`,
  tw`mt-0.5 flex self-center mr-2`,
]);

export const Button = styled.a(() => [
  `:hover{background-color:#53565A;}`,
  `
  :focus {
    outline: 1px dashed #333;
    outline-offset: 3px;
  }
  `,
  tw`h-12 block mt-2 text-white text-center leading-[3rem] bg-background2 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-full cursor-pointer
  transition-all duration-200
  `,
]);

export const ModalButton = styled.button(() => [
  `:hover{background-color:#53565A;}`,
  `
  :focus {
    outline: 1px dashed #333;
    outline-offset: 3px;
  }
  `,
  tw`h-12 block mt-2 text-white text-center leading-[3rem] bg-background2 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-full cursor-pointer
  transition-all duration-200
  `,
]);

export const ButtonDisabled = styled.span(() => [
  `background-color:rgba(0,0,0,0.5);`,
  tw`h-12 block mt-2 text-white text-center leading-10 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-full`,
]);

export const New = styled.div(() => [
  ` top: -31px; right: -6px; padding: 6px 12px 7px; font-family: BentonSans; font-size: 12px; font-weight: 700; line-height: 1;`,
  `background: #006FCF; box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);`,
  `&[data-modal="modal"] {top:-23px;} `,
  tw`absolute text-white text-center uppercase rounded-2xl whitespace-nowrap overflow-hidden`,
]);

export const LocationInfo = styled.div(() => [``, tw``]);

export const LocationOuter = styled.div(() => [``, tw`flex flex-1`]);

export const LocationInner = styled.div(() => [
  ``,
  tw`flex flex-col flex-1 justify-center content-center`,
]);

export const HotelRestaurantStar = styled.div(() => [tw`pl-3`]);

export const CardBodyGrouped = styled.div(() => [
  `flex-direction: column; flex: 1;`,
  tw`flex text-center relative`,
]);
