const lang = {
  intro: {
    title: "Explore The World of Dining",
    paragraph:
      "<p>As part of your Platinum Card® Global Dining Credit benefit, we’ve curated a selection of over 2000+ restaurants in %COUNT% countries for you to explore. Whether it’s a new dining experience locally or on the other side of the world, Platinum will take care of it.</p><p>Enrolment is required via Amex Offers in your Online Account or American Express® App.</p>",
    tcLink:
      '<a href="%PATH%Terms-and-Conditions-GB.pdf" target="_blank" rel="noopener">View full dining benefit details and T&Cs</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "Country:",
    state: "State:",
    stateDefault: "All states",
    city: "City:",
    cityDefault: "All cities",
    cuisine: "Cuisine:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants not available for this selection",
    viewmap: "View on map",
    makebooking: "To make a booking please visit:",
    makebookingTel: "To make a booking please call:",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Delivery/Take-Away available",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisine types",
    close: "Close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only pre-paid Pocket Concierge bookings are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  platinumBanner: {
    title: "Explore Platinum Directory",
    text: "Explore all your Card benefits across travel, entertainment,events, insurance and more. Exclusive to Platinum CardMembers. Terms and conditions apply.",
    button: "Visit Platinum Benefits Directory",
  },
  footer: {
    disclaimer:
      "<p>Enrolment is required via Amex Offers in your Online Account or American Express® App. Details of the Offer are available to view in the terms and conditions when enrolling into the Offer.</p><p>”Participating local restaurants” means participating restaurants located in the UK</p><p>”Participating abroad restaurants” are participating restaurants located outside of the UK</p><p>Copyright © 2023 American Express Company</p>",
  },
};

export default lang;
