const lang = {
  intro: {
    title: "Explorez les restaurants à travers le pays",
    paragraph:
      "<p>Nous avons préparé avec soin une sélection de restaurants qui ont figuré sur la liste des 100 meilleurs restaurants du Canada au cours des dernières années. Vous pourrez les visiter pour profiter de votre crédit annuel de 200 $ pour repas.<br><br>Vous devez vous inscrire par l'entremise des offres Amex dans votre compte des Services en ligne ou dans l'application American Express<sup>MD</sup>.</p>",
    tcLink:
      '<a href="%PATH%FR-CA-T&Cs.pdf" target="_blank" rel="noopener">Voir toutes les modalités et informations de l’avantage</a>',
  },
  filters: {
    country: "Pays :",
    state: "State :",
    stateDefault: "All states",
    city: "Ville :",
    cityDefault: "Toutes les villes",
    cuisine: "Cuisine :",
    cuisineDefault: "Toutes les gastronomies",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants non disponibles pour cette sélection",
    viewmap: "Afficher dans Google Maps",
    makebooking: "Pour faire une réservation, veuillez visiter :",
    makebookingTel: "Pour réserver, appelez le :",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Emporter / livraison disponible",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NOUVEAU",
    locations: "locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisines",
    close: "close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only restaurants accepted prepaid are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>* Ce restaurant est dans un hôtel. Si l’achat au restaurant n’apparaît pas sur votre relevé en tant qu’opération détaillée séparée (par exemple, si vous avez porté le repas au compte de votre chambre et il fait partie de la facture pour votre séjour), il pourrait être inadmissible au crédit annuel de 200 $ pour repas. Pour être admissible, l’achat doit être porté à votre Carte directement par le restaurant, puis ce dernier (pas l’hôtel) doit figurer sur votre relevé en tant que marchand.</p><br/><p>Seuls les titulaires d’une Carte de Platine principale qui ont reçu cette offre y sont admissibles. Inscription obligatoire. Vous pourrez consulter tous les détails de l’offre dans les modalités au moment de l’inscription. La liste des restaurants admissibles est mise à jour de temps à autre et peut ne pas être entièrement exacte.</p></br><p>Veuillez noter qu’en visitant le site Web d’un restaurant admissible, vous quittez le site d’American Express. Nous ne sommes pas responsables du contenu de tout site qui n’appartient pas à American Express et qui n’est pas exploité par American Express.</p><p>© American Express Company, 2023</p>",
  },
  platinumBanner: {
    title: "Explorer tous vos avantages",
    text: "Repoussez les limites du possible pour ce qui vous tient à cœur grâce aux avantages, aux expériences et aux récompenses que vous offre La Carte de Platine <sup>MD</sup> d'American Express.",
    button: "Explorer maintenant",
  },
};

export default lang;
