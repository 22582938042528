// Elements
import { DetailSVG } from "./Results.elements";

export function IconBell() {
  return (
    <DetailSVG width="18" height="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.125 14.25a.625.625 0 01.06 1.247L.875 15.5a.625.625 0 01-.06-1.247l16.31-.003zM10.25.5a.625.625 0 01.06 1.248l-.685.003v1.307c3.798.316 6.803 3.47 6.874 7.332l.001 1.985a.625.625 0 01-.565.622L2.125 13a.625.625 0 01-.622-.565L1.5 10.53c0-3.88 2.96-7.076 6.738-7.46l.137-.012V1.75H7.75A.625.625 0 017.69.503L10.25.5zM7.61 5.724C6.368 6.011 5.415 6.76 4.723 7.81a6.725 6.725 0 00-.72 1.462l-.118.363a4.021 4.021 0 00-.082.326.625.625 0 001.21.303l.059-.233.036-.122a5.49 5.49 0 01.659-1.411c.527-.8 1.224-1.348 2.123-1.556a.625.625 0 10-.282-1.218z"
        fill="#000"
        fillRule="nonzero"
      />
    </DetailSVG>
  );
}

export function IconCuisine() {
  return (
    <DetailSVG width="14" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.298 0a.625.625 0 00-.625.625V3.75h-1.25V.625a.625.625 0 00-1.25 0V3.75h-1.25V.625a.625.625 0 10-1.25 0v5c0 1.034.84 1.875 1.875 1.875h.625v11.875a.625.625 0 001.25 0V7.5h.625a1.877 1.877 0 001.875-1.875v-5A.625.625 0 006.298 0zm6.218.001a.625.625 0 01.656.56l.003.064v18.75a.625.625 0 01-1.247.06l-.003-.06v-7.187l-.031.001c-.773 0-1.39-.228-1.834-.677-.806-.815-.89-2.163-.885-3.749l.01-1.229.001-.284c0-3.458 1.432-6.145 3.33-6.249zm-.591 10.938V1.553c-.77.662-1.453 2.35-1.487 4.524l-.002.462-.01 1.154c-.007 1.3.043 2.456.523 2.94.192.195.468.29.872.304l.104.002V1.553v9.386zM5.673 5v.625c0 .345-.28.625-.625.625h-2.5a.626.626 0 01-.625-.625V5h3.75z"
        fill="#000"
        fillRule="nonzero"
      />
    </DetailSVG>
  );
}

export function IconPin() {
  return (
    <DetailSVG width="14" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 0C3.134 0 0 3.174 0 7.09c0 2.868 3.758 9.104 5.767 12.232.271.422.735.678 1.232.678.498 0 .962-.255 1.234-.677C10.24 16.195 14 9.959 14 7.09 14 3.174 10.866 0 7 0zm0 9.972c-1.614 0-2.923-1.326-2.923-2.96 0-1.635 1.309-2.96 2.923-2.96s2.923 1.325 2.923 2.96c0 1.634-1.309 2.96-2.923 2.96z"
        fill="#006FCF"
        fillRule="nonzero"
      />
    </DetailSVG>
  );
}

export function IconTakeout() {
  return (
    <DetailSVG width="16" height="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.923 0c2.03 0 3.69 1.623 3.748 3.64l.002.11h1.25c.663 0 1.21.523 1.248 1.177l.002.073 1.25 13.75c0 .663-.523 1.21-1.177 1.248l-.073.002h-12.5c-.663 0-1.21-.523-1.248-1.177l-.002-.073L1.673 5c0-.663.523-1.21 1.177-1.248l.073-.002h1.25A3.754 3.754 0 017.923 0zM4.17 5H2.919l-.002.057-.003.056L1.673 18.75h12.5l-1.24-13.637-.005-.056L12.927 5h-1.251v1.25a.625.625 0 01-1.248.06l-.003-.06V5H5.42v1.25a.625.625 0 01-1.248.06l-.003-.06V5zm3.753-3.75a2.503 2.503 0 00-2.498 2.406l-.002.094h5c0-1.379-1.122-2.5-2.5-2.5z"
        fill="#000"
        fillRule="nonzero"
      />
    </DetailSVG>
  );
}

export function IconStar() {
  return (
    <DetailSVG width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7501276,0.778239114 C11.2613932,-0.259413038 12.7386068,-0.259413038 13.2498724,0.778239114 L16.2026006,6.77102366 L22.8051014,7.73201135 C23.9483261,7.89840661 24.4048102,9.30564593 23.577565,10.1133455 L18.7999506,14.7780777 L19.9277924,21.3647853 C20.1230784,22.5052754 18.9279875,23.3749971 17.9054563,22.8365308 L12,19.7267093 L6.09454375,22.8365308 C5.07201251,23.3749971 3.87692156,22.5052754 4.07220765,21.3647853 L5.20004943,14.7780777 L0.422434965,10.1133455 C-0.404810182,9.30564593 0.051673941,7.89840661 1.19489862,7.73201135 L7.79739943,6.77102366 L10.7501276,0.778239114 Z M12,1.5 L8.75532156,8.08359214 L1.5,9.13932023 L6.75,14.263932 L5.51064312,21.5 L12,18.0835921 L18.4893569,21.5 L17.25,14.263932 L22.5,9.13932023 L15.2446784,8.08359214 L12,1.5 Z"
        id="Favorite"
        fill="#006FCF"
        fill-rule="nonzero"
      ></path>
    </DetailSVG>
  );
}
