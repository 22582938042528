export const setCardWidth = () => {
  const root = document.documentElement;
  if (window && document.getElementById("filters")) {
    const w = window.innerWidth;
    const cw = document.getElementById("filters").offsetWidth;
    let offset = 0;
    let cols = 1;
    if (w >= 768) {
      cols = 2;
      offset = 16;
      root.style.setProperty("--card-width", `${(cw - offset) / cols}px`);
    }
    if (w >= 1024) {
      // root.style.setProperty('--card-width', "320px");
      cols = 3;
      offset = 32;
      root.style.setProperty("--card-width", `${(cw - offset) / cols}px`);
    }
  }
}
