const lang = {
  intro: {
    title: "探索精彩的餐饗世界",
    paragraph:
      "<p>我們在%COUNT%個國家搜羅逾1,400家高級餐廳，讓您無論在本港或海外地方，都可享有白金卡環球餐饗消費額禮遇，盡情探索風味各異的佳餚美饌。</p>",
    tcLink:
      '<a href="%PATH%Dual-Language-Terms-and-Conditions-Hong-Kong.pdf" target="_blank" rel="noopener">查閱餐饗禮遇之詳情及條款細則</a>',
    hotelRestaurant:
      "*如參與之餐廳位於閣下之住宿酒店內，此禮遇需於餐廳內直接付款，不能掛賬於酒店房間內。",
  },
  filters: {
    country: "國家 或 地區：",
    state: "省：",
    stateDefault: "All states",
    city: "城市：",
    cityDefault: "所有城市",
    cuisine: "菜式：",
    cuisineDefault: "所有料理",
  },
  results: {
    halal: "Halal",
    notAvailable: "此選擇不提供餐廳",
    viewmap: "在地圖上查看",
    makebooking: "如需預訂，請瀏覽：",
    makebookingTel: "如需預訂，請致電：",
    orderOnline: "如需預訂，請瀏覽：",
    deliveryTakeaway: "提供外送和外賣",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "新加入",
    locations: "位置",
    button: "點擊查看地點",
    groupCuisine: "不同菜式種類",
    close: "關閉",
    bookNow: "立即預訂",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description: "尋找並預訂日本最好的餐廳 (只適用接受預先付款之餐廳)", // "尋找並預訂日本最好的餐廳 (只適用接受預先付款之餐廳)",
    cuisines: "不同菜式種類",
    instruction: "如需預訂，請瀏覽：",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>需於消費前預先登記, 並僅適用於 白金卡基本卡會員。</p><p>詳情請參閱登記推廣優惠時之條款及細則。</p><p>「參與之本地餐廳」之定義為參與餐廳位於香港特別行政區。</p><p>「參與之海外餐廳」之定義為參與餐廳位於香港特別行政區境外。</p><p>有關優惠的詳情，請參閱您收到的推廣電子郵件 [在本地及世界各地享受精緻餐饗]。</p><p>版權所有 © 2023 美國運通國際股份有限公司</p>",
  },
  platinumBanner: {
    title: " Explore Platinum Directory",
    text: " Explore all your Card benefits across travel, entertainment,events, insurance and more. Exclusive to Platinum CardMembers.",
    button: "Visit Platinum Benefits Directory",
  },
};

export default lang;
