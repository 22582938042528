const lang = {
  intro: {
    title: "Entdecken Sie Ihre Dining Vorteile",
    paragraph:
      "<p>Im Rahmen der Ihrer American Express Card Gourmet-Gutschrift haben wir eine Auswahl von über 2000+ Restaurants in %COUNT% Ländern für Sie zusammengestellt.</br>Ob in Ihrem Lieblingsrestaurant in Österreich oder auf Ihren Reisen, genießen Sie feinste Kulinarik von den besten Köch:innen der Welt. Wo werden Sie das nächste Mal essen gehen?</p>",
    tcLink:
      '<a href="%PATH%Angebotsbedingungen-Osterreich.pdf" target="_blank" rel="noopener">Alle Details zu Ihrer Gourmet-Gutschrift und den Angebotsbedingungen finden Sie hier.</a>',
    hotelRestaurant:
      "* Bitte beachten Sie, dass bei Restaurants in Hotels das Guthaben nur gewährt wird, wenn die Restaurantrechnung mit dem Restaurant beglichen wird und nicht mit der Hotelrechnung.",
  },
  filters: {
    country: "Land:",
    state: "Land:",
    stateDefault: "All states",
    city: "Stadt:",
    cityDefault: "Alle Städte",
    cuisine: "Kategorie:",
    cuisineDefault: "Alle Kategorien",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants für diese Auswahl nicht verfügbar",
    viewmap: "Auf Karte anzeigen",
    makebooking: "Für eine Tischreservierung, besuchen Sie bitte:",
    makebookingTel: "Für eine Tischreservierung rufen Sie an:",
    orderOnline: "Für eine Tischreservierung besuchen Sie bitte:",
    deliveryTakeaway: "Lieferung / zum Mitnehmen möglich",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEU",
    locations: "Standorte",
    button: "Standorte anzeigen",
    groupCuisine: "Verschiedenes",
    close: "Schließen",
    bookNow: "Jetzt reservieren",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Entdecken Sie alle Vorteile Ihrer Platinum Card in den Bereichen Reisen, Unterhaltung, Events, Versicherungen u.v.m.. Exklusiv für Platinum Card Inhaber:innen.",
    cuisines: "Verschiedenes",
    instruction: "Für eine Tischreservierung, besuchen Sie bitte:",
    button: "Visit Pocket Concierge",
  },
  platinumBanner: {
    title: "The Platinum Card® Vorteil",
    text: "Entdecken Sie alle Vorteile Ihrer Platinum Card in den Bereichen Reisen, Unterhaltung, Events, Versicherungen u.v.m.<br/><br/>Exklusiv für Platinum Card Inhaber:innen.",
    button: "Platinum Vorteile entdecken",
  },
  centurionBanner: {
    title: "The Centurion Card® Vorteile",
    text: "Entdecken Sie alle Vorteile Ihrer Centurion Card in den Bereichen Reisen, Unterhaltung, Events, Versicherungen u.v.m.<br/><br/>Exklusiv für Centurion Mitglieder.",
    button: "Centurion Vorteile entdecken",
  },
  footer: {
    disclaimer:
      "<p>Erfordert eine Registrierung und gilt nur für Centurion und Platinum Hauptkarteninhaber:innen.</p><p>Die vollständigen Angebotsdetails finden Sie auf der jeweiligen Webseite.</p><p>Copyright © 2023 American Express Company</p>",
  },
};

export default lang;
