// Packages
import { useContext, useEffect, useState } from "react";
// import debounce from "lodash.debounce";

// Context
import { Language } from "../../Language/Language";

// Elements
import {
  Container,
  Cross,
  Card,
  CardBody,
  CardFooter,
  Title,
  Address,
  Detail,
  Button,
  ButtonDisabled,
  RestaurantBanner,
  CloseBanner,
  CloseButton,
  CardWrapper,
} from "./Modal.elements";

import { New, HotelRestaurantStar } from "./Results.elements";
import { IconCuisine, IconPin, IconStar } from "./Results.icons";

import auMerchantOverrides from "./auMerchantOverides";

function ModalMerchants({
  merchants,
  filterCallback,
  locale,
  country,
  isReady,
}) {
  const { results: copy } = useContext(Language);

  // function checkTakeoutFlag() {
  //   return props.isTakeoutAvailable ?? false;
  // }

  useEffect(() => {
    setTimeout(() => {
      setEqualHeights();
      if (isReady) {
        isReady(true);
      }
    }, 0);

    // let debFunc = debounce(() => {
    //   handleResize();
    // }, 50);

    // handleResize();

    // window.addEventListener("resize", debFunc);

    // return () => {
    //   if (debFunc) {
    //     window.removeEventListener("resize", debFunc);
    //   }
    // };
  }, []);

  // function handleResize() {
  //   updateShuffle();
  // }

  function setEqualHeights(childItemSelector) {
    const elements = Array.from(
      document.querySelectorAll("#modal .modal-card")
    );
    if (elements.length) {
      const heights = [];
      elements.forEach((el) => {
        heights.push(el.offsetHeight);
      });
      const maxHeight = Math.max(...heights);
      elements.forEach((el) => {
        el.querySelector("div").style.height = `${maxHeight}px`;
      });
    }
  }

  function getTranslation(key, translations) {
    const market = locale.replace("-", "_");
    if (translations[market] && translations[market][key]) {
      return translations[market][key];
    }
    // Fall back to "en" value...
    return translations["en"][key];
  }

  function translate(item, translations) {
    const market = locale.replace("-", "_");
    if (!item || !item.hasOwnProperty("translations")) {
      throw Error(`Translations object not in ${JSON.stringify(item)}`);
    }
    const f = item.translations[market] || item.translations["en"];
    return f.title;
  }

  return (
    <>
      {merchants.map(
        ({
          id,
          name,
          address,
          cuisine,
          city,
          postcode,
          businessData,
          translations,
          googleMapsUrl,
          // takeoutAvailable,
          onlineOnly,
          isHalal,
        }) => {
          const {
            isNew,
            website,
            phone,
            openingSoon,
            resyVenueId,
            isInHotel,
          } = businessData;
          const hasWebsite = website && website.length;
          const hasPhone = phone && phone.length;
          const hasAuOverride =
            country === "AU" && auMerchantOverrides.includes(id);
          const hasHalal = isHalal === true;
          const hotelRestaurant = isInHotel === true;

          return (
            <div
              key={id}
              className="col-6@xs col-6@sm col-4@md modal-card"
              data-groups={`["${city.id}","${cuisine ? cuisine.id : "none"}"]`}
            >
              <Card>
                <CardBody>
                  {isNew && <New data-modal="modal">{copy.new}</New>}

                  <Title>
                    {getTranslation("name", translations)}
                    {hotelRestaurant && (
                      <HotelRestaurantStar>
                        <IconStar />
                      </HotelRestaurantStar>
                    )}
                  </Title>

                  {!onlineOnly && (
                    <Address>
                      {getTranslation("address", translations)}
                      <br />
                      {translate(city)}
                      <br />
                      {getTranslation("postcode", translations)}
                    </Address>
                  )}

                  {onlineOnly && !address && (
                    <Address>{copy.onlineOnly}</Address>
                  )}

                  {onlineOnly && address && (
                    <Address>{getTranslation("address", translations)}</Address>
                  )}

                  {hasAuOverride && (
                    <div>Restaurant participation temporarily suspended</div>
                  )}

                  {!hasAuOverride && !onlineOnly && googleMapsUrl && (
                    <Detail>
                      <IconPin />
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={googleMapsUrl}
                      >
                        {copy.viewmap}
                      </a>
                    </Detail>
                  )}

                  {!hasAuOverride && cuisine && (
                    <Detail>
                      <IconCuisine />
                      {translate(cuisine)}
                    </Detail>
                  )}
                  {/* 
                  {takeoutFlag && takeoutAvailable && (
                    <Detail>
                      <IconTakeout />
                      {copy[takeoutAvailable]}
                    </Detail>
                  )} */}
                  {hasHalal && <Detail>{copy.halal}</Detail>}
                </CardBody>

                {hasAuOverride && <CardFooter />}

                {!hasAuOverride && (
                  <CardFooter>
                    {resyVenueId ? (
                      <div id={id}></div>
                    ) : (
                      <>
                        {hasWebsite && (
                          <>
                            {openingSoon ? (
                              <ButtonDisabled>
                                {website.split("/")[2].replace("www.", "")}
                              </ButtonDisabled>
                            ) : (
                              <Button
                                rel="noopener noreferrer"
                                target="_blank"
                                href={website}
                              >
                                {copy.bookNow}
                              </Button>
                            )}
                          </>
                        )}
                        {!hasWebsite && hasPhone && (
                          <>
                            {openingSoon ? (
                              <ButtonDisabled>
                                {website.split("/")[2].replace("www.", "")}
                              </ButtonDisabled>
                            ) : (
                              <Button
                                rel="noopener noreferrer"
                                href={`tel:${phone}`}
                              >
                                {phone}
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </CardFooter>
                )}
              </Card>
            </div>
          );
        }
      )}
    </>
  );
}

export default function Modal({
  locale,
  country,
  merchants,
  merchant,
  closeModal,
}) {
  const { results: copy } = useContext(Language);
  const [modalVisible, setModalVisible] = useState(false);
  const isReady = (readyState) => {
    setModalVisible(readyState);
  };

  function translateMerchantName(item) {
    const market = locale.replace("-", "_");
    if (!item || !item.hasOwnProperty("translations")) {
      throw Error(`Translations object not in ${JSON.stringify(item)}`);
    }
    const f = item.translations[market] || item.translations["en"];
    return f.name;
  }

  return (
    <Container
      id="modal"
      tabindex="0"
      style={{ visibility: modalVisible ? "" : "hidden" }}
    >
      <RestaurantBanner>
        {translateMerchantName(merchant)} {copy.locations}
        <Cross
          onClick={closeModal}
          // onKeyUp={(e) => {
          //   console.log(e.keyCode);
          //   if (e.keyCode === 27 || e.keyCode === 13 || e.keyCode === 32) {
          //     closeModal();
          //   }
          // }}
        >
          <span className="visuallyHidden">{copy.close}</span>
        </Cross>
      </RestaurantBanner>
      <CardWrapper>
        <div className="row">
          <ModalMerchants
            merchants={merchants}
            isReady={isReady}
            locale={locale}
            country={country}
          />
        </div>
      </CardWrapper>
      <CloseBanner>
        <CloseButton role="button" tabindex="0" onClick={closeModal}>
          {copy.close}
        </CloseButton>
      </CloseBanner>
    </Container>
  );
}
