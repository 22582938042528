const lang = {
  intro: {
    title: "Ihr Platinum Card Restaurantguthaben",
    paragraph:
      "<p>Gehen Sie auf kulinarische Entdeckungsreise und entdecken Sie teilnehmende Restaurants auf der ganzen Welt. Dafür steht Ihnen jährlich ein Restaurantguthaben von insgesamt 150 Euro zur Verfügung, das Sie in mehr als 2000+ Restaurants in über %COUNT% Ländern einlösen können. Aktivieren Sie Ihr Guthaben, reservieren Sie Ihren Tisch ganz einfach direkt in einem der teilnehmenden Restaurants und zahlen Sie mit Ihrer Platinum Card.</p>",
    tcLink:
      '<a href="%PATH%Angebotsbedingungen-Deutschland.pdf" target="_blank" rel="noopener">Alle Details zu Ihrem Restaurantguthaben und Angebotsbedingungen finden Sie hier.</a>',
    hotelRestaurant:
      "* Bitte beachten Sie, dass bei Restaurants in Hotels das Guthaben nur gewährt wird, wenn die Restaurantrechnung mit dem Restaurant beglichen wird und nicht mit der Hotelrechnung.",
  },
  filters: {
    country: "Land:",
    state: "Staat:",
    stateDefault: "Alle Staat",
    city: "Stadt:",
    cityDefault: "Alle Städte",
    cuisine: "Kategorie:",
    cuisineDefault: "Alle Kategorien",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants für diese Auswahl nicht verfügbar",
    viewmap: "Auf Karte anzeigen",
    makebooking: "Tischreservierung unter:",
    makebookingTel: "Telefonische Reservierung unter:",
    orderOnline: "Bestellungen unter:",
    deliveryTakeaway: "Lieferung / zum Mitnehmen möglich",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEU",
    locations: "Standorte",
    button: "Standorte anzeigen",
    groupCuisine: "Verschiedenes",
    close: "Schließen",
    bookNow: "Jetzt reservieren",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Entdecken Sie die besten Restaurants in Japan (nur Restaurants mit Vorauszahlung)",
    cuisines: "Verschiedenes",
    instruction: "Tischreservierung unter:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>Die Anmeldung ist über AMEX Offers in Ihrem Online-Kartenkonto oder der American Express App erforderlich.</p><p>Dieser Vorteil gilt nur für Karteninhaber:innen einer Platinum Hauptkarte.</p><p>Die vollständigen Einzelheiten des Vorteils sind in den <a href="http://www.amex.de/platinum-bedingungen" target="_blank">Angebotsbedingungen</a> zu finden.</p><p>Für die Anmeldung klicken Sie bitte <a href="https://m.amex/SbwgL2" target="_blank">hier</a>.</p><p>Copyright © 2023 American Express Company</p>',
  },
  platinumBanner: {
    title: "Platinum Vorteile",
    text: "Entdecken Sie weitere Platinum-Vorteile im Platinum Portal",
    button: "Platinum Vorteile entdecken",
  },
};

export default lang;
