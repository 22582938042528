// Packages
import tw, { styled } from "twin.macro";

// Images
import HeroBg from "../../_images/full_background.webp";
import HeroBgSml from "../../_images/full_background_small.jpg";

export const Main = styled.main(() => [
  `background-color:#404143; background-image: url("${HeroBgSml}"); background-position: 50% 0; background-repeat: no-repeat;`,
  `@media screen and (min-width: 768px) {background-image: url("${HeroBg}"); background-size: 100vw auto}`,
  tw`relative min-h-screen overflow-x-hidden `
]);

export const Container = styled.div(() => [
  ``,
  tw`container mx-auto px-4 relative z-10`
]);
