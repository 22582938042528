const lang = {
  intro: {
    title: "Explore The World of Dining",
    paragraph:
      "<p>As part of your American Express Global Dining Credit benefit, we’ve curated a selection of over 2000+ restaurants in %COUNT% countries for you to explore. So whether it’s a new restaurant locally or on the other side of the world, where will you dine next?</p>",
    tcLink:
      '<a href="%PATH%Terms-and-Conditions-BE.pdf" target="_blank" rel="noopener">View full dining benefit details and T&Cs</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "Country:",
    state: "State:",
    stateDefault: "All states",
    city: "City:",
    cityDefault: "All cities",
    cuisine: "Cuisine:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants not available for this selection",
    viewmap: "View on map",
    makebooking: "To make a booking please visit:",
    makebookingTel: "Phone to book:",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Delivery/Take-Away available",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisines",
    close: "close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only restaurants accepted prepaid are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>Requires enrolment and applicable only for Centurion and Platinum Cardmembers. For Platinum enrolment, click here for <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=beplatbonus070122&channel=IBW&locale=fr-BE">French</a> & here for <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=beplatbonus070122&channel=IBW&locale=nl-BE">Dutch</a>. For Centurion enrolment, <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=becentbonus070122&channel=IBW&locale=en-BE">click here</a>.</p>Details of the Offer are available to view in the terms and conditions when enrolling into the Offer.</p><p>Participating local restaurants means participating restaurants located in Belgium and Luxembourg.</p><p>Participating restaurants abroad are participating restaurants located outside of Belgium and Luxembourg.</p><p>Important note: Deposits when booking the restaurant or prepayment of diners are excluded from the Dining Credit. These are usually handled by a third party, not directly with the restaurant and so not recognized.</p><p>Copyright © 2023 American Express Company</p>',
  },
  platinumBanner: {
    title: " Explore Platinum Directory",
    text: " Explore all your Card benefits across travel, entertainment,events, insurance and more. Exclusive to Platinum CardMembers.",
    button: "Visit Platinum Benefits Directory",
  },
};

export default lang;
