// Packages
import { useEffect, useState, useContext } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { Helmet } from "react-helmet";

// Language
import { Language } from "../../Language/Language";

// Store
import {
  filters,
  countryId,
  countryData,
  modalMerchants,
  showMerchantsModal,
  modalMerchant,
  filterHalal,
  merchantsData,
} from "../../Store/Store";

// Elements
import {
  ModalMask,
  Main,
  Logo,
  Container,
  Button,
  Title,
  Paragraph,
  Checkbox,
} from "./Listing.elements";

import Modal from "./Modal";

import { BannerWrapper } from "./BannerWrapper.elements";
import { PlatinumBanner, CenturionBanner } from "./Banner.elements";

import { useParams } from "react-router-dom";

// Components
import Intro from "./Intro";
import Filters from "./Filters";
import Results from "./Results";
// import Pocket from "./Pocket";
import PocketCard from "./PocketCard";
// import Temp from "./Temp";
import Footer from "../Common/Footer";
import LangSelect from "../Common/LangSelect";

export default function Listing() {
  const { locale } = useParams();

  const { platinumBanner: platinumCopy, centurionBanner: centurionCopy } =
    useContext(Language);
  const { country } = useRecoilValue(filters);
  const id = useRecoilValue(countryId);
  const countries = useRecoilValue(countryData);

  const groupedMerchants = useRecoilValue(modalMerchants);
  const groupMerchant = useRecoilValue(modalMerchant);
  const [filterHalalMerchants, setFilterHalalMerchants] =
    useRecoilState(filterHalal);

  const [showModal, setShowModal] = useRecoilState(showMerchantsModal);
  const merchants = useRecoilValue(merchantsData);

  const [width, setWidth] = useState(375);
  const [isJP, setIsJP] = useState(false);
  const [countryCount, setCountryCount] = useState("0");
  const [platinumButtonUrl, setPlatinumButtonUrl] = useState("");
  const [centurionButtonUrl, setCenturionButtonUrl] = useState("");
  const areThereHalalMerchants = hasHalalMerchants(merchants);
  const twoFilters =
    locale === "fr-fr" || locale === "fr-ca" || locale === "en-ca";

  function handleResize() {
    setWidth(window.visualViewport.width);
  }

  function showPlatinumBanner(locale) {
    return !!platinumButtonUrl;
  }

  function showCenturionBanner(locale) {
    return !!centurionButtonUrl;
  }

  function hasInHotelRestaurantMerchants(merchants) {
    if (merchants) {
      return merchants.some((merchant) => {
        return merchant.businessData.isInHotel;
      });
    } else {
      return false;
    }
  }

  function hasHalalMerchants(merchants) {
    if (merchants) {
      return merchants.some((merchant) => {
        return merchant.businessData.isHalal;
      });
    } else {
      return false;
    }
  }

  useEffect(() => {
    setIsJP(locale.split("-")[1] === "jp");
    let count = 20;
    if (countries && countries.length) {
      let arr = [];
      countries.forEach((c) => {
        arr = arr.concat([...c.countries]);
      });
      arr = arr.filter(
        (f) => (f.isActive && f.isVisibleToOtherMarkets) || f.key === id
        // f => f.isVisibleToOtherMarkets || f.key === id
      );
      // console.log(arr, arr.length);
      count = arr.length;
    }
    setCountryCount("" + count + "");
  }, [country]);

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const urls = {
      "en-gb":
        "https://www.americanexpress.com/en-gb/benefits/the-platinum-card/",
      "de-de":
        "https://www.amex-kreditkarten.de/infocenter/benefit-portal/platinum-card/intro",
      "en-au":
        "https://www.americanexpress.com/en-au/credit-cards/membership-benefits/the-platinum-card/",
      "en-sg": "https://go.amex/myplatinum",
      "zh-tw": "https://www.americanexpress.com/tw/platinum",
      "de-at": "https://www.americanexpress.com/de-at/vorteile/platinum-card/",
      "ja-jp": "https://www.americanexpress.com/ja-jp/benefits/platinum-card/",
      "en-ca":
        "https://www.americanexpress.com/ca/en/benefits/the-platinum-card/",
      "fr-ca":
        "https://www.americanexpress.com/ca/fr/avantages/la-carte-de-platine/",

      // "mx-es": "#",
      // "it-it": "#",
    };
    setPlatinumButtonUrl(urls[locale]);
  }, []);

  useEffect(() => {
    const urls = {
      "de-at":
        "https://www.americanexpress.com/at/vorteile/centurion/index.html",
      // "mx-es": "#",
      // "it-it": "#",
    };
    setCenturionButtonUrl(urls[locale]);
  }, []);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (
        showModal &&
        (e.keyCode === 27 || e.keyCode === 13 || e.keyCode === 32)
      ) {
        setShowModal(false);
      }
    };

    window.document.addEventListener("keyup", handleKeyUp);

    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showModal]);

  function closeModal() {
    setShowModal(false);
  }

  function enableHalalFilter() {
    filterHalalMerchants
      ? setFilterHalalMerchants(false)
      : setFilterHalalMerchants(true);
  }

  return (
    <>
      <Helmet>
        <title>American Express - Dining Offer</title>
      </Helmet>
      <Logo>
        <span className="visuallyHidden">American Express</span>
      </Logo>
      <Main isJP={isJP} data-locale={locale}>
        {showModal && (
          <>
            <ModalMask />
            <Modal
              merchants={groupedMerchants}
              merchant={groupMerchant}
              closeModal={closeModal}
              locale={locale}
              country={country}
            ></Modal>
          </>
        )}
        <Container>
          <Intro
            locale={locale}
            countryCount={countryCount}
            hasInHotel={hasInHotelRestaurantMerchants(merchants)}
          />
          {areThereHalalMerchants && !twoFilters && (
            <Checkbox>
              <input
                type="checkbox"
                id="halal"
                name="halal"
                onClick={enableHalalFilter}
              />
              <label for="halal"> Halal</label>
            </Checkbox>
          )}
          <Filters />

          {country === "JP" ? (
            <PocketCard />
          ) : (
            <Results
              locale={locale}
              country={country}
              filterHalalMerchants={filterHalalMerchants}
            />
          )}

          {showPlatinumBanner(locale) && showCenturionBanner(locale) && (
            <BannerWrapper isPair={true}>
              <PlatinumBanner paired={true}>
                <Title>{platinumCopy.title}</Title>
                <Paragraph
                  paired={true}
                  width={width}
                  dangerouslySetInnerHTML={{ __html: platinumCopy.text }}
                />
                <Button width={width} href={platinumButtonUrl} target="_blank">
                  {platinumCopy.button}
                </Button>
              </PlatinumBanner>
              <CenturionBanner paired={true}>
                <Title>{centurionCopy.title}</Title>
                <Paragraph
                  paired={true}
                  width={width}
                  dangerouslySetInnerHTML={{ __html: centurionCopy.text }}
                />
                <Button width={width} href={centurionButtonUrl} target="_blank">
                  {centurionCopy.button}
                </Button>
              </CenturionBanner>
            </BannerWrapper>
          )}

          {showPlatinumBanner(locale) && !showCenturionBanner(locale) && (
            <BannerWrapper>
              <PlatinumBanner>
                <Title>{platinumCopy.title}</Title>
                <Paragraph
                  paired={true}
                  width={width}
                  dangerouslySetInnerHTML={{ __html: platinumCopy.text }}
                />{" "}
                <Button width={width} href={platinumButtonUrl} target="_blank">
                  {platinumCopy.button}
                </Button>
              </PlatinumBanner>
            </BannerWrapper>
          )}

          {showCenturionBanner(locale) && !showPlatinumBanner(locale) && (
            <BannerWrapper>
              <CenturionBanner>
                <Title>{centurionCopy.title}</Title>
                <Paragraph
                  paired={true}
                  width={width}
                  dangerouslySetInnerHTML={{ __html: platinumCopy.text }}
                />{" "}
                <Button
                  width={width}
                  href={centurionButtonUrl}
                  target="_blank"
                  data-width={width}
                >
                  {centurionCopy.button}
                </Button>
              </CenturionBanner>
            </BannerWrapper>
          )}
          <Footer isJP={isJP} locale={locale} />
        </Container>
        <LangSelect locale={locale} />
      </Main>
    </>
  );
}
