// Packages
import { useContext } from "react";

// Context
import { Language } from "../../Language/Language";

// Elements
import {
  Container,
  Title,
  Paragraph,
  TcLink,
  HotelRestaurantInfo,
} from "./Intro.elements";

export default function Intro({ countryCount, locale, hasInHotel }) {
  const { intro } = useContext(Language);
  const { title, paragraph, tcLink, hotelRestaurant } = intro;

  function setCount(copy) {
    if (typeof copy === "string" && copy.includes("%COUNT%")) {
      return copy.replace(/%COUNT%/, countryCount);
    }
    return copy;
  }

  function setPath(copy, path) {
    if (typeof copy === "string" && copy.includes("%PATH%")) {
      return copy.replace(/%PATH%/, path);
    }
    return copy;
  }

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: setCount(paragraph) || "",
          }}
        ></Paragraph>
        <TcLink
          dangerouslySetInnerHTML={{
            __html: setPath(tcLink, `/${locale}/benefits/diningbenefit/`) || "",
          }}
        ></TcLink>
        {
          hasInHotel &&
          (locale !== "fr-ca" || locale !== "en-ca") &&
          (hotelRestaurant !== "") && (
            <HotelRestaurantInfo>{hotelRestaurant}</HotelRestaurantInfo>
          )
        }
      </Container>
    </>
  );
}
