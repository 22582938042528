// Packages
import tw, { styled } from "twin.macro";

// 60px / 70px line height on desktop
// 24px / 29px line height on mobile
// Both BentonSans Black

// bg: #53565A
// font: #D0D1CB

export const Main = styled.main(() => [
  `background-color: #53565A;`,
  tw`min-h-screen overflow-x-hidden`,
]);

export const Container = styled.div(() => [
  `
  padding-top: 60px; 
  padding-bottom: 60px;
  @media screen and (min-width: 1024px) {
    padding: 80px 60px;
  }
  `,
  tw`container mx-auto px-4 relative z-10`,
]);

export const Title = styled.h1(() => [
  `
  font-family: "Benton Sans Bla";
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.1667;
  max-width: 420px;
  @media screen and (min-width: 1024px) {
    font-size: 56px;
    max-width: 730px;
    margin-bottom: 60px;
  }
  span {
    display: block;
    color: #D0D1CB;
  }
  `,
  tw`text-white`,
]);

export const PorterLogo = styled.div(() => [
  `
  width: 160px;
  margin: 60px 0 30px;
  @media screen and (min-width: 495px) and (max-width: 1023px) {
    width: 204px;
  }
  @media screen and (min-width: 1024px) {
    margin: 0;
    position: absolute;
    top: 80px;
    right: 60px;
  }
  @media screen and (min-width: 1563px) {
    right: 120px;
  }
  `,
  tw``,
]);

export const CardArt = styled.div(() => [
  `
  min-height: 300px;
  @media screen and (max-width: 1023px) {
    margin-top: -80px;
  }
  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 230px;
    max-width: 50%;
    right: 0;
  }
  `,
  tw``,
]);

export const Buttons = styled.div(() => [
  `
  @media screen and (max-width: 1563px) {
    max-width: 358px;
    margin: 0 auto 60px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 425px;
    margin-left: 0;
    margin-bottom: 90px;
  }
  @media screen and (min-width: 1563px) {
    display: flex;
    align-items: center;
    margin-bottom: 180px;
    max-width: calc(50% + 30px);
  }
  `,
  tw``,
]);

export const ButtonPrimary = styled.a(() => [
  `
  display: block;
  width: 100%;
  color: #fff;
  background: #006fcf;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: all .2s ease-in-out;
  transition-property: color,background-color,border-color;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  padding: 28px 28px;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 4px;

  &:hover {
    background: #0061b6;
  }
  &:focus {
    outline: 1px dashed #fff;
    outline-offset: 3px;
  }
  `,
  tw``,
]);

export const ButtonSecondary = styled.a(() => [
  `
  display: block;
  width: 100%;
  color: #fff;
  border: 1px solid white;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: all .2s ease-in-out;
  transition-property: color,background-color,border-color;
  user-select: none;
  vertical-align: middle;
  padding: 17px 28px;
  font-size: 1rem;
  line-height: 1.375;
  border-radius: 4px;

  &:focus {
    outline: 1px dashed #fff;
    outline-offset: 3px;
  }
  &:last-child {
    @media screen and (max-width: 1562px) {
      margin-top: 24px;
    }
    @media screen and (min-width: 1563px) {
      margin-left: 24px;
    }
  }
  `,
  tw``,
]);

export const Footer = styled.div(() => [
  `
  display: flex;
  justify-items: speace-between;
  align-items: center;
  @media screen and (max-width: 1023px) {
    max-width: 358px;
    margin: 0 auto;
  }
  `,
  tw``,
]);

export const AltLogo = styled.div(() => [
  `
    width: 50px;
    height: 50px;
    margin-right: 24px;
  `,
  tw``,
]);

export const Tagline = styled.div(() => [
  `
  img {width: 100%;}
  @media screen and (max-width: 1023px) {
    flex: 1 auto;
  }
  `,
  tw``,
]);
