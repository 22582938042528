const lang = {
  intro: {
    title: "Explore The World of Dining",
    paragraph:
      "<p>As part of your Platinum Card® Global Dining Credit benefit, we’ve curated a selection of over 2000+ restaurants in %COUNT% countries for you to explore. Whether it’s a new dining experience locally or on the other side of the world, Platinum will take care of it.</p>",
    tcLink:
      '<a href="https://go.amex/sgplatdincredit" target="_blank" rel="noopener">View full dining benefit details and T&Cs</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "Country:",
    state: "State:",
    stateDefault: "All states",
    city: "City:",
    cityDefault: "All cities",
    cuisine: "Cuisine:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants not available for this selection",
    viewmap: "View on map",
    makebooking: "To make a booking please visit:",
    makebookingTel: "Phone to book:",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Take-Away available",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "Locations",
    button: "Click to view locations",
    groupCuisine: "Various cuisines types",
    close: "Close",
    bookNow: "Book now",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Discover and book the best restaurants in Japan (only restaurants accepted prepaid are eligible)",
    cuisines: "Various cuisines",
    instruction: "To make a booking please visit:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>This benefit requires enrolment and is only applicable for The Platinum Card® Members. Further details to be found in the Terms and Conditions when enrolling into the benefit.</BR>Updated as of December 2022.</p><p>Copyright © 2023 American Express Company</p>",
  },
  platinumBanner: {
    title: "Platinum Benefits Directory",
    text: "Explore all your Card benefits across travel, entertainment & events, insurance and more. Exclusive to Platinum Card Members.",
    button: "Visit Platinum Benefits Directory",
  },
};

export default lang;
