const lang = {
  intro: {
    title: "ดื่มด่ำกับโลกแห่งอาหารนานาชาติ",
    paragraph:
      '<p>เปิดประสบการณ์มื้อพิเศษกับบัตรแพลทินัมด้วยสิทธิประโยชน์ Global Dining Credit รับเครดิตเงินคืนเมื่อรับประทานอาหาร ณ ร้านอาหารที่คัดสรรมาโดยเฉพาะกว่า 1,400 แห่ง ใน %COUNT% ประเทศ ไม่ว่าจะเป็นเมนูที่คุ้นเคยในไทยหรืออาหารนานาชาติในต่างแดน</p><p>สำหรับการใช้สิทธิประโยชน์ ณ ร้านอาหารในประเทศไทย สมาชิกบัตรต้องชำระด้วยบัตรแพลทินัมเท่านั้น ไม่สามารถใช้การชำระผ่าน Digital Wallet หรือช่องทางอื่น ๆ</p><p><a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=bonusplatthai0102&channel=IBW&locale=th-TH">คลิกเพื่อลงทะเบียน</a></p>',
    tcLink:
      '<a href="%PATH%Thailand-Global-Dining-Credit-T&C-TH.pdf" target="_blank" rel="noopener">ดูข้อมูลเพิ่มเติมและข้อกำหนดและเงื่อนไข</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "ประเทศ:",
    state: "รัฐ:",
    stateDefault: "All states",
    city: "เมือง:",
    cityDefault: "All cities",
    cuisine: "ประเภทอาหาร:",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "ไม่มีร้านอาหารให้เลือก",
    viewmap: "ดูแผนที่",
    makebooking: "ทำการสำรองโต๊ะล่วงหน้า:",
    makebookingTel: "หมายเลขติดต่อเพื่อจอง:",
    orderOnline: "สั่งอาหารได้ที่:",
    deliveryTakeaway: "ซื้อกลับบ้านได้",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "เปิดให้บริการเร็วๆนี้",
    onlineOnly: "Online Only",
    new: "ใหม่",
    locations: "ที่ตั้งของร้าน",
    button: "คลิกเพื่อดูสถานที่ตั้ง",
    groupCuisine: "หลากหลายประเภทอาหาร",
    close: "ปิด",
    bookNow: "สำรองโต๊ะ",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "ค้นหาและสำรองโต๊ะร้านอาหารชั้นนำในญี่ปุ่น (ใช้ได้เฉพาะร้านอาหารที่รับชำระเงินล่วงหน้าเท่านั้น) ท่านจะได้รับเครดิตเงินคืนเมื่อสำรองโต๊ะผ่านเว็บไซต์ Pocket Concierge เท่านั้น",
    cuisines: "หลากหลายประเภทอาหาร",
    instruction: "ทำการสำรองโต๊ะล่วงหน้า:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>ท่านต้องทำการลงทะเบียนก่อนเพื่อใช้สิทธิประโยชน์</p><p>สิทธิประโยชน์นี้สำหรับสมาชิกบัตรแพลทินัมเท่านั้น</p><p><a href="%PATH%Thailand-Global-Dining-Credit-T&C-TH.pdf" target="_blank" rel="noopener">ดูรายละเอียดเพิ่มเติมและข้อกำหนดและเงื่อนไข</a></p><p>"ร้านอาหารในประเทศที่ร่วมรายการ" หมายถึงร้านอาหารที่ร่วมรายการที่ตั้งอยู่ในประเทศไทย</p><p>"ร้านอาหารในต่างประเทศที่ร่วมรายการ" หมายถึงร้านอาหารที่ร่วมรายการที่ตั้งอยู่นอกประเทศไทย</p><p>ลงทะเบียนกรุณา <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=bonusplatthai0102&channel=IBW&locale=th-TH" target="_blank">คลิกที่นี่</a></p><p>สงวนลิขสิทธิ์ © 2023 บริษัทอเมริกัน เอ็กซ์เพรส</p>',
  },
  platinumBanner: {
    title: "Platinum Benefits Directory",
    text: "ดูสิทธิประโยชน์อื่น ๆ ใน Platinum Benefits Directory",
    button: "Visit Platinum Benefits Directory",
  },
};

export default lang;
