const lang = {
  intro: {
    title: "Profitez des meilleures tables contemporaines",
    paragraph:
      "<p>Dans le cadre de votre Bénéfice Carte Platinum, nous avons sélectionné plus de 200 restaurants à découvrir en France dans les plus grands restaurants mais aussi au sein des établissements qui font vibrer la nouvelle scène gastronomique. American Express vous offre 100% de remise sur vos additions jusqu’à 200€ !</p>",
    tcLink:
      '<a href="%PATH%Termes-et-Conditions-Credit-Dining.pdf" target="_blank" rel="noopener">Voir tous les détails du bénéfice et les conditions générales</a>',
    hotelRestaurant:
      "",
  },
  filters: {
    country: "Pays :",
    state: "State :",
    stateDefault: "All states",
    city: "Ville:",
    cityDefault: "All cities",
    cuisine: "Cuisine:",
    cuisineDefault: "Type de cuisine",
  },
  results: {
    halal: "Halal",
    notAvailable: "",
    viewmap: "Voir sur la carte",
    makebooking: "Pour réserver, rendez-vous sur :",
    makebookingTel: "Pour réserver par téléphone :",
    orderOnline: "Pour commander, veuillez consulter le site :",
    deliveryTakeaway: "Emporter / livraison disponible",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "Nouveau",
    locations: "Lieux",
    button: "Cliquez pour voir les lieux",
    groupCuisine: "Types de cuisines variés",
    close: "Fermer",
    bookNow: "Réserver maintenant",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Découvrez et réservez auprès des meilleurs restaurants au Japon (seuls les restaurants qui acceptent les prépaiements sont éligibles)",
    cuisines: "Types de cuisines variés",
    instruction: "Pour réserver, rendez-vous sur :",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      "<p>* Veuillez noter que si vous séjournez dans un hôtel, ce crédit ne sera appliqué que lors du règlement de la facture du restaurant directement avec le restaurateur, séparément du paiement global de l’hôtel.</p><p>Inscription obligatoire et offre réservée aux titulaires de la Carte Platinum American Express.</p><p>De plus amples informations sont disponibles dans les conditions générales lors de l’inscription à l’offre.</p><p>Copyright © 2023 American Express Company</p>",
  },
  platinumBanner: {
    title: "Tous les bénéfices de votre Carte Platinum",
    text: "Re-découvrez tous les bénéfices de votre Carte Platinum : pour vos voyages (longs et courts séjours), vos assurances, vos évènements, et bien plus encore. Des avantages conçus spécifiquement pour nos titulaires Platinum.",
    button: "Re-découvrez tous les bénéfices de votre Carte Platinum",
  },
};

export default lang;
