// Packages
import tw, { styled, css } from "twin.macro";

export const Container = styled.div(() => [
  ``,
  tw`container mx-auto text-white pt-60 md:pt-20`
]);

export const Title = styled.h1(() => [
  `letter-spacing:-0.41px;`,
  tw` text-title pb-4 text-center leading-none md:leading-snug md:pb-2`
]);

const introContent = css`
  p + p {
    margin-top: 1em;
  }
`;

// export const Paragraph = styled.p(() => [
export const Paragraph = styled.div(() => [
  ``,
  tw`text-copy pb-7 px-4 text-center md:px-0`,
  introContent
]);
