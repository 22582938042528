// Packages
import { atom, selector } from "recoil";

export const countryId = atom({
  key: "countryId",
  default: null,
});

export const cityId = atom({
  key: "cityId",
  default: null,
});

export const modalMerchants = atom({
  key: "modalMerchants",
  default: [],
});

export const modalMerchant = atom({
  key: "modalMerchant",
  default: null,
});

export const showMerchantsModal = atom({
  key: "showMerchantsModal",
  default: false,
});

export const cuisineId = atom({
  key: "cuisineId",
  default: null,
});

export const filterHalal = atom({
  key: "filterHalal",
  default: false,
});

export const countryData = selector({
  key: "countryData",
  get: async () => {
    const res = await fetch(`${process.env.REACT_APP_API_PATH}/api/countries`);
    return await res.json();
  },
});

export const cityData = selector({
  key: "cityData",
  get: async ({ get }) => {
    const id = get(countryId);

    if (!id) return null;

    const res = await fetch(
      `${process.env.REACT_APP_API_PATH}/api/country/${id}/cities`
    );

    return await res.json();
  },
});

export const cuisineData = selector({
  key: "cuisineData",
  get: async ({ get }) => {
    const id = get(countryId);

    if (!id) return null;

    const res = await fetch(
      `${process.env.REACT_APP_API_PATH}/api/country/${id}/cuisine`
    );
    return await res.json();
  },
});

export const merchantsData = selector({
  key: "merchantsData",
  get: async ({ get }) => {
    const id = get(countryId);

    if (!id) return null;

    const res = await fetch(
      `${process.env.REACT_APP_API_PATH}/api/country/${id}/merchants`
    );
    return await res.json();
  },
});

export const countryProps = selector({
  key: "countryProps",
  get: ({ get }) => {
    const countries = get(countryData);
    const id = get(countryId);
    let props = null;
    countries.forEach((region) => {
      const country = region.countries.find((c) => c.key === id);
      if (country && !props) {
        props = country;
      }
    });
    return props || {};
  },
});

export const filters = selector({
  key: "filters",
  get: ({ get }) => {
    return {
      country: get(countryId),
      city: get(cityId),
      cuisine: get(cuisineId),
    };
  },
});
