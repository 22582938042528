const lang = {
  intro: {
    title: "Ontdek een wereld van culinair genot.",
    paragraph:
      "<p>Als onderdeel van uw American Express Dining voordeel, hebben we een selectie van meer dan 2000+ restaurants in %COUNT% landen samengesteld die u kunt ontdekken. Of het nu gaat om een nieuwe culinaire ervaring in België, in Luxemburg óf aan de andere kant van de wereld. Waar gaat u binnenkort dineren?</p>",
    tcLink:
      '<a href="%PATH%Algemene-voorwaarden-Belgium-and-Luxembourg.pdf" target="_blank" rel="noopener">Bekijk de volledige details van het voordeel en de algemene voorwaarden</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "Land :",
    state: "Staat:",
    stateDefault: "All states",
    city: "Stad :",
    cityDefault: "All cities",
    cuisine: "Keuken :",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants niet beschikbaar voor deze selectie",
    viewmap: "Kijk op de kaart",
    makebooking: "Om te reserveren, ga naar:",
    makebookingTel: "Telefonisch reserveren:",
    orderOnline: "Om te bestellen, ga naar:",
    deliveryTakeaway: "Bezorgen / afhalen mogelijk",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "Nieuw",
    locations: "Locaties",
    button: "Klik om locaties te bekijken",
    groupCuisine: "Verschillende soorten keukens",
    close: "Sluiten",
    bookNow: "Reserveer nu",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Ontdek en boek de beste restaurants in Japan (alleen beschikbaar voor restaurants die vooruitbetaling accepteren)",
    cuisines: "Various cuisines",
    instruction: "Om te reserveren, ga naar:",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>Inschrijving verplicht en alleen van toepassing voor Centurion en Platinum Kaarthouders. Voor Platinum, klik <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=beplatbonus070122&channel=IBW&locale=nl-BE">hier</a>. Voor Centurion, klik <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=becentbonus070122&channel=IBW&locale=en-BE">hier</a>.</p><p>Meer informatie en de voorwaarden vindt u bij inschrijving voor het voordeel.</p><p>Deelnemende restaurants in het buitenland omvatten alle deelnemende restaurants buiten België en Luxemburg.</p><p>Let op : Aanbetaling voor het reserveren bij het restaurant en volledig vooruitbetaalde diners zijn uitgesloten van het dinertegoed. Ze worden vaak afgehandeld door een derde partij en niet rechtstreeks met het restaurant waardoor de betaling niet herkend wordt.</p><p>Copyright © 2023 American Express Company</p>',
  },
};

export default lang;
