const lang = {
  intro: {
    title: "Assapora il mondo dell'alta cucina",
    paragraph:
      "<p>Con Global Dining potrai esplorare oltre 2000+ ristoranti in %COUNT% paesi. Che si tratti di una nuova esperienza culinaria in Italia o dall’altra parte del mondo, la tua Carta sarà al tuo fianco.</p>",
    tcLink:
      '<a href="%PATH%Termini-e-Condizioni-Italia.pdf" target="_blank" rel="noopener">Verifica i termini e condizioni del beneficio</a>',
    hotelRestaurant:
      "* Per poter usufruire del beneficio si ricorda che, se si soggiorna in un Hotel, lo sconto verrà applicato solo effettuando il pagamento del conto direttamente presso il ristorante, separatamente dal pagamento complessivo del soggiorno in Hotel.",
  },
  filters: {
    country: "Paese:",
    state: "Stato:",
    stateDefault: "All states",
    city: "Città:",
    cityDefault: "Tutte",
    cuisine: "Cucina:",
    cuisineDefault: "Tutte le tipologie",
  },
  results: {
    halal: "Halal",
    notAvailable: "Ristoranti non disponibili per questa selezione",
    viewmap: "Guarda sulla mappa",
    makebooking: "Per prenotare visita:",
    makebookingTel: "Per prenotare chiama:",
    orderOnline: "Per ordinare visita:",
    deliveryTakeaway: "Consegna / asporto disponibile",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "Novità",
    locations: "location",
    button: "Clicca qui per scoprire le location",
    groupCuisine: "Tutte le tipologie",
    close: "Chiudi",
    bookNow: "Prenota ora",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Scopri e prenota i migliori ristoranti del Giappone (valido per i ristoranti che accettano prepagati)",
    cuisines: "Tutte le tipologie",
    instruction: "Per prenotare visita:",
    button: "Visit Pocket Concierge",
  },
  platinumBanner: {
    title: "Visita il sito e i programmi Platino e Centurion",
    text: "Scopri i benefici di Carta Platino e Centurion tra viaggi, eventi,  intrattenimento, assicurazioni incluse e tanto altro.",
    button: "Visita il sito e i programmi Platino",
  },
  centurionBanner: {
    title: "Centurion Benefits Directory",
    text: "Explore all your Card benefits across travel, entertainment & events, insurance and more. Exclusive to Centurion Cardmembers.",
    button: "Visit Centurion Benefits Directory",
  },
  footer: {
    disclaimer:
      '<p>Per usufruire dello sconto Ristoranti in estratto conto di 150 euro in Italia e 150 euro all\'estero, è necessaria l’iscrizione preventiva al benficio, riservata esclusivamente ai Titolari di Carta Base Platino e Centurion American Express. Per ulteriori informazioni su Termini & Condizioni <a href="/it-it/benefits/diningbenefit/Termini-e-Condizioni-Italia.pdf">clicca qui</a>.</p><p>Al beneficio Global Dining partecipano ristoranti situati in Italia e ristoranti situati all\'estero</p><p>Per i dettagli completi dell\'offerta, fai riferimento alla comunicazione ricevuta via e-mail o nella tua area riservata su <a href="https://www.americanexpress.it">americanexpress.it</a>.</p><p>Copyright © 2023 American Express Company</p>',
  },
};

export default lang;
