// Packages
import tw, { styled, css } from "twin.macro";

// Images
import HeroBg from "../../_images/full_background.webp";
import HeroBgSml from "../../_images/full_background_small.jpg";
import LogoAsset from "../../_images/amex.svg";

// Modal mask
export const ModalMask = styled.div(() => [
  `background-color: rgba(0,0,0,0.5);`,
  tw`absolute w-full h-full z-40`,
]);

export const Logo = styled.div(() => [
  `width:150px; height:41px; left: 20px; top: 32px; background-image: url("${LogoAsset}");`,
  tw`absolute z-10`,
]);

export const Main = styled.main(({ isJP }) => [
  `background-color:#404143; background-image: url("${HeroBgSml}"); background-position: 50% 0; background-repeat: no-repeat;`,
  `@media screen and (min-width: 768px) {background-image: url("${HeroBg}"); background-size: 100vw auto}`,
  tw`relative min-h-screen overflow-x-hidden `,
]);

// export const BackgroundFlourish = styled.div(() => [
//   `background-image: url("${Tile}");`,
//   tw`min-h-screen bg-no-repeat`
// ]);

export const Container = styled.div(() => [
  ``,
  tw` max-w-5xl mx-auto px-4 relative z-10`,
]);

// export const Diagonal = styled.div(({ width }) => {
//   const mobBp = 480; // 375
//   // const isMobile = width <= mobBp;

//   const img = width > 768 ? Tile : width > mobBp ? Tile : HeroSmall;
//   const size = width > 768 ? "100%" : width > mobBp ? "100%" : "";

//   return [
//     `min-height: 100%; background-image: url("${img}"); background-size:${size};`,
//     tw`w-full top-0 left-0 right-0 z-0 bg-no-repeat `
//   ];
// });

export const Button = styled.a(({ width }) => {
  const mobBp = 480;
  // const size = width > 768 ? "33%" : width > mobBp ? "100%" : "";
  const size = width > 768 ? "var(--card-width)" : width > mobBp ? "100%" : "";

  return [
    `color:#006FCF; width:${size}; font-size: 16px; font-family: BentonSans;`,
    tw`h-12 md:h-12 sm:h-14 block mt-2 pt-3 text-center bg-white rounded pl-1 pr-1 cursor-pointer`,
  ];
});

export const Title = styled.h2(() => [
  `letter-spacing:-0.41px;`,
  `font-size: 30px;`,
  tw`pb-4 leading-none md:leading-snug md:pb-2 font-light text-white`,
]);

const introContent = css`
  p + p {
    margin-top: 1em;
  }
`;

// export const Paragraph = styled.p(() => [
export const Paragraph = styled.div(({ paired, width }) => {
  const h = width > 480 && !paired ? "2rem" : "5rem";
  return [
    `min-height: ${h};`,
    tw`text-copy  text-white lg:max-w-2xl pb-4 pr-2`,
    introContent,
  ];
});

export const Checkbox = styled.div(() => [
  ``,
  tw`text-copy text-white text-right relative `,
  introContent,
]);
