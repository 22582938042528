// Packages
import tw, { styled } from "twin.macro";

import ArrowDown from "../../_images/arrow-down.svg";

export const Container = styled.div(() => [
  `@media screen and (max-width: 1024px) { justify-content:space-between};`,
  tw`md:max-w-screen-md lg:max-w-screen-lg mx-auto text-white text-copy md:flex lg:gap-4 mb-7`,
]);

export const Filter = styled.div(() => [
  `@media screen and (min-width: 768px) {width: calc((100% - 32px)/3);}`,
  tw``,
]);

export const Label = styled.label(() => [
  ``,
  tw`pb-1 block md:pr-2 md:pb-0 mb-0.5 font-medium`,
]);

export const Select = styled.select(() => [
  `appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;`,
  `cursor: pointer;
  text-overflow: ellipsis;
  border-radius: 0.25rem;
  border:1px solid #C8C9C7;`,
  `background: #F7F8F9;
  background-image: url(${ArrowDown});
  background-size: 16px 16px;
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  `,
  `
  :focus {
    outline: 1px dashed #fff;
    outline-offset: 3px;
  }
  `,
  tw`text-black h-12 leading-10 w-full mb-6 md:h-12 md:mb-0 px-2.5`,
]);

export const Checkbox = styled.div(() => [
  ``,
  tw`text-copy text-white text-right items-center flex `,
]);

export const CheckboxContents = styled.div(() => [
  ``,
  tw`text-copy text-white text-right mt-5 `,
]);
