// Packages
import { useEffect } from "react";
import { Helmet } from "react-helmet";

// Elements
import {
  Main,
  Container,
  Title,
  PorterLogo,
  CardArt,
  Buttons,
  ButtonPrimary,
  ButtonSecondary,
  Footer,
  Tagline,
  AltLogo,
} from "./Interstitial.elements";

// Images
import CardImage from "../../_images/int-card.png";
import PorterImage from "../../_images/int-porter.png";
import TaglineImage from "../../_images/int-tagline.svg";
import AltBlueImage from "../../_images/dls-logo-bluebox-alt.svg";

// Components
// import Copy from "./Copy";
// import Footer from "./Footer";

const primaryLink = "https://www.amex-kreditkarten.de/platinum-shopping/?sourcesuffix=A0000FCJ0P&cpid=100485137";
const secondaryLink = "https://c00.adobe.com/v3/9e8596e4b1e60359e6a6cf0ac99c0584e0744d4c69135212067eaebae5c75480/start?a_dl=62ba0677e252c62fe6e39ed6";

export default function Interstitial({ onReady }) {

  useEffect(() => {
    if (onReady) {
      onReady();
    }
  }, []);

  return (
    <Main>

      <Helmet>
        <title>American Express Platinum</title>
        {/* <link href="//cloud.typenetwork.com/projects/4493/fontface.css/" rel="stylesheet" type="text/css" /> */}
      </Helmet>

      <Container>

        <Title>
          Jetzt die Farben des Sommers entdecken.
          <span>Mit Platinum das nächste Summermust-have finden.</span>
        </Title>

        <PorterLogo>
          <img src={PorterImage} alt="NET-A-PORTER MR PORTER" />
        </PorterLogo>

        <CardArt>
          <img src={CardImage} alt="Platinum Card" />
        </CardArt>

        <Buttons>
          <ButtonPrimary href={primaryLink}>
            Platinum Card beantragen
          </ButtonPrimary>
          <ButtonSecondary href={secondaryLink}>
            Bereits Platinum Kartenhaber:in?<br/>Jetzt Shoppingguthaben aktivieren
          </ButtonSecondary>
        </Buttons>

        <Footer>
          <AltLogo>
            <img src={AltBlueImage} alt="American Express" />
          </AltLogo>
          <Tagline>
            <img src={TaglineImage} alt="Don't live life without it™" />
          </Tagline>
        </Footer>

      </Container>
    </Main>
  );
}
