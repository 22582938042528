// Packages
// import { useContext } from "react";

// Context
// import { Language } from "../../Language/Language";

// Components
// import Analytics from "../Common/Analytics";

// Elements
import { Container, Disclaimer, Logo } from "./Footer.elements";

export default function Footer() {
  // const { footer: copy } = useContext(Language);

  return (
    <Container>
      <Disclaimer>
        <p>Copyright © 2021 American Express Company</p>
      </Disclaimer>
      <Logo />
      {/* <Analytics /> */}
    </Container>
  );
}
