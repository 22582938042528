import React from "react";
import { createGlobalStyle } from "styled-components";
import { GlobalStyles as BaseStyles } from "twin.macro";

const CustomStyles = createGlobalStyle`

  @font-face {
    font-family: Guardian;
    font-font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://www.aexp-static.com/cdaas/one/statics/@americanexpress/static-assets/2.25.0/package/dist/fonts/guardianregular.woff) format("woff"),
    url(https://www.aexp-static.com/cdaas/one/statics/@americanexpress/static-assets/2.25.0/package/dist/fonts/guardianregular.woff2) format("woff2")
  }

  @font-face {
    font-family: BentonSans;
    font-weight: 300;
    src: url(https://www.aexp-static.com/nav/ngn/fonts/325e6ad0-38fb-4bad-861c-d965eab101d5-2.eot);
    src: url(https://www.aexp-static.com/nav/ngn/fonts/325e6ad0-38fb-4bad-861c-d965eab101d5-2.eot)
        format("embedded-opentype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/325e6ad0-38fb-4bad-861c-d965eab101d5-3.woff)
        format("woff"),
      url(https://www.aexp-static.com/nav/ngn/fonts/325e6ad0-38fb-4bad-861c-d965eab101d5-1.ttf)
        format("truetype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/325e6ad0-38fb-4bad-861c-d965eab101d5-4.svg)
        format("svg");
  }
  @font-face {
    font-family: BentonSans;
    font-weight: 400;
    src: url(https://www.aexp-static.com/nav/ngn/fonts/3be50273-0b2e-4aef-ae68-882eacd611f9-2.eot);
    src: url(https://www.aexp-static.com/nav/ngn/fonts/3be50273-0b2e-4aef-ae68-882eacd611f9-2.eot)
        format("embedded-opentype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/3be50273-0b2e-4aef-ae68-882eacd611f9-3.woff)
        format("woff"),
      url(https://www.aexp-static.com/nav/ngn/fonts/3be50273-0b2e-4aef-ae68-882eacd611f9-1.ttf)
        format("truetype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/3be50273-0b2e-4aef-ae68-882eacd611f9-4.svg)
        format("svg");
  }
  @font-face {
    font-family: BentonSans;
    font-weight: 500;
    src: url(https://www.aexp-static.com/nav/ngn/fonts/0fababca-4914-46dd-9b0f-efbd51f67ae8-2.eot);
    src: url(https://www.aexp-static.com/nav/ngn/fonts/0fababca-4914-46dd-9b0f-efbd51f67ae8-2.eot)
        format("embedded-opentype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/0fababca-4914-46dd-9b0f-efbd51f67ae8-3.woff)
        format("woff"),
      url(https://www.aexp-static.com/nav/ngn/fonts/0fababca-4914-46dd-9b0f-efbd51f67ae8-1.ttf)
        format("truetype"),
      url(https://www.aexp-static.com/nav/ngn/fonts/0fababca-4914-46dd-9b0f-efbd51f67ae8-4.svg)
        format("svg");
  }

  @font-face {
    font-family: "Benton Sans Bla";
    font-weight: normal;
    font-style: normal;
    font-display: swap;

    src: url("//cloud.typenetwork.com/projectLicenseWeb/32525/fontfile/eot/?2bc83cd37a03d08388ff026c59ab49cdba681705");
    src: url("//cloud.typenetwork.com/projectLicenseWeb/32525/fontfile/eot/?2bc83cd37a03d08388ff026c59ab49cdba681705#iefix") format("embedded-opentype"),
      url("//cloud.typenetwork.com/projectLicenseWeb/32525/fontfile/woff2/?2bc83cd37a03d08388ff026c59ab49cdba681705") format("woff2"),
      url("//cloud.typenetwork.com/projectLicenseWeb/32525/fontfile/woff/?2bc83cd37a03d08388ff026c59ab49cdba681705") format("woff");
  }

  html {
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }

  #root {
    position: relative;
  }

  .visuallyHidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .consentContainer {
    background-color: #283E48 !important;
  }

  input.btn_accept {
    color: #283E48 !important;
  }

  input.btn_accept:focus {
    color: #283E48 !important;
    outline: 1px dashed rgba(255,255,255,0.3) !important;
    outline-offset: 3px !important;
  }

  input.btn_decline:focus {
    outline: 1px dashed rgba(255,255,255,0.3) !important;
    outline-offset: 3px !important;
  }

  input.btn_decline:hover {
    background: #1a282e !important;
  }

`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
