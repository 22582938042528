// Packages
import tw, { styled, css } from "twin.macro";

export const Container = styled.div(() => [
  ``,
  tw`container mx-auto text-white pt-60 md:pt-80 mb-10`,
]);

export const Title = styled.h1(() => [
  `letter-spacing:-0.41px;`,
  tw` text-title pb-4 text-center font-light leading-none md:leading-snug md:text-center md:pb-2`,
]);

const introContent = css`
  p + p {
    margin-top: 1em;
  }
  a {
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
`;

// export const Paragraph = styled.p(() => [
export const Paragraph = styled.div(() => [
  ``,
  tw`text-introcopy font-light px-4 lg:px-14 text-center md:text-center md:px-0 mx-auto`,
  introContent,
]);

export const TcLink = styled.div(() => [
  `a {
    &:link, &:visited, &:hover, &:focus, &:active {
      text-decoration: underline;
    }
    &:focus {
      outline: 1px dashed #fff;
      outline-offset: 3px;
    }
  }`,
  tw`text-introcopy px-4 text-center md:text-center md:px-0 mt-8 mx-auto`,
]);

export const HotelRestaurantInfo = styled.div(() => [
  tw`text-copy px-6 text-left px-4 mt-8 mx-auto flex row-auto`,
  introContent,
]);
