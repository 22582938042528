import { createContext } from "react";

import * as langs from "../_locales/_index";

export const languages = Object.keys(langs).reduce((acc, key) => {
  const fallback = langs["en_gb"];

  return { ...acc, [key.replace("_", "-")]: { ...fallback, ...langs[key] } };
}, {});

export const Language = createContext(languages["en-gb"]);
