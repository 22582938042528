import tw, { styled } from "twin.macro";
import Flourish from "../../_images/background-flourish.svg";
import CloseIcon from "../../_images/close.svg";

export const Container = styled.div(() => [
  `height: 90%; width: calc(90% - 32px);`,
  `@media screen and (min-width: 768px) { height: auto; max-height:75%; max-width:75%;}`,
  `@media screen and (min-width: 1024px) { max-height:560px; max-width:1024px;}`,
  `transition: 0.5s ease;`,
  // tw`grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`,
  tw` mx-auto fixed bg-gray-200 top-1/2 left-1/2 rounded-lg transform -translate-x-1/2 -translate-y-1/2 flex justify-center z-50 `,
]);

export const ErrorMsg = styled.div(() => [
  ``,
  tw`flex justify-center text-white`,
]);

export const Card = styled.div(() => [
  `transition: transform 0.1s ease-in-out; &:hover{transform: scale(1.05);}; background-image: url("${Flourish}"); background-repeat: no-repeat; background-size: cover;`,
  tw`flex flex-col text-copy bg-white p-4 pt-3 border border-gray-300 shadow-sm`,
]);

export const CardWrapper = styled.div(() => [
  `padding: 80px 16px;`,
  `> div {width: 100%;}`,
  `> div > div { margin-bottom: 1rem; }`,
  tw`w-full flex flex-row flex-wrap overflow-scroll z-[-1] justify-center	`,
]);

export const CardBody = styled.div(() => [``, tw`flex-1 text-center relative`]);

export const CardFooter = styled.div(() => [``, tw`mt-4 text-center`]);

export const Title = styled.h2(() => [
  `letter-spacing:-0.41px;`,
  tw`text-cardtitle pb-3 mb-4 font-serif leading-9`,
]);

export const Address = styled.div(() => [``, tw`text-sm leading-5 mb-4`]);

export const Detail = styled.div(() => [
  `a{color:#006FCF}+ div {margin-top:0.6875rem;}`,
  `
    a {
      &:focus {
        outline: 1px dashed currentColor;
        outline-offset: 3px;
      }
    }
  `,
  tw`text-sm leading-snug flex content-center justify-center`,
]);

export const RestaurantBanner = styled.div(() => [
  `background-color:#333333;`,
  tw`h-16 text-white pt-5 text-center fixed w-full block rounded-t-lg`,
]);

export const CloseBanner = styled.div(() => [
  `border-color:#D3D4D5;`,
  tw`h-20 bg-gray-200 text-white pt-2 border-t-2 rounded-b-lg  text-center fixed w-full block bottom-0 flex justify-center`,
]);

export const Instruction = styled.div(({ marginBottom, weight }) => [
  `font-weight:${weight ? weight : 400};margin-bottom:${
    marginBottom ? marginBottom : 0
  }px;`,
  tw` flex`,
]);

export const DetailSVG = styled.svg(() => [
  `min-width:16px;`,
  tw`flex self-center mr-2`,
]);

export const Button = styled.a(() => [
  `:hover{background-color:#53565A;}`,
  `
  :focus {
    outline: 1px dashed #333;
    outline-offset: 3px;
  }
  `,
  tw`h-12 block mt-2 text-white text-center leading-[3rem] bg-background2 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-full`,
]);

export const CloseButton = styled.button(() => [
  `
  :focus {
    outline: 1px dashed #333;
    outline-offset: 3px;
  }
  `,
  tw`h-12 block mt-2 text-white text-center leading-[3rem] bg-background2 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-52 cursor-pointer`,
]);

export const ButtonDisabled = styled.span(() => [
  `background-color:rgba(0,0,0,0.5);`,
  tw`h-12 block mt-2 text-white text-center leading-10 rounded whitespace-nowrap overflow-hidden overflow-ellipsis pl-3 pr-3 w-full`,
]);

export const Cross = styled.button(() => [
  `
  display: block;
  background-image: url(${CloseIcon});
  background-size: 16px 16px;
  width: 16px;
  height: 16px;
  `,
  `
  :focus {
    outline: 1px dashed #fff;
    outline-offset: 3px;
  }
  `,
  tw`absolute right-4 bottom-1/3 cursor-pointer`,
]);
