// Packages
import tw, { styled } from "twin.macro";

// Images
// import Amex from "../../_images/amex.png";
import Amex from "../../_images/bluebox.svg";

export const Container = styled.footer(() => [
  ``,
  tw`box-content container mx-auto mt-48 pb-14 md:pb-20 border-t border-gray-300 relative md:mt-96`,
]);

export const Disclaimer = styled.div(() => [
  `color:white;`,
  tw`pt-9 text-copy px-4 text-center md:px-0 md:pr-8 md:text-left md:w-5/6 lg:w-4/6 lg:pr-0`,
]);

export const Logo = styled.div(() => [
  `background-image: url("${Amex}"); width:48px; height:48px; background-size: 48px 48px;`,
  tw`w-12 h-12 bg-white bg-contain mx-auto mt-4 block md:mt-0 md:absolute md:right-0 md:top-10`,
]);
