// Packages
// import { useEffect, useState } from "react";

// Elements
import { Main, Container } from "./NotFound.elements";

// Components
import Copy from "./Copy";
import Footer from "./Footer";

export default function NotFound() {
  return (
    <Main>
      <Container>
        <Copy />
        <Footer />
      </Container>
    </Main>
  );
}
