const lang = {
  intro: {
    title: "《白金美饌‧非同凡饗》",
    paragraph:
      "<p>我們精心策劃一系列最受饕客喜愛來自%COUNT%個國家超過2,000間的知名餐廳，任您挑選！不論您在台灣或置身海外，皆能享受美食款待！活動期間美國運通簽帳白金卡於全球指定餐廳消費，可享有餐飲抵用額度禮遇，讓您盡情探索道地的精彩美饌。</p>",
    tcLink:
      '<a href="//go.amex/taiwangdc" target="_blank" rel="noopener">參閱活動細則與注意事項</a>',
    hotelRestaurant:
      "* 請留意，若您於指定餐廳的飯店入住時，用餐後請務必直接於餐廳以美國運通簽帳白金卡（不含其他支付工具）當場簽付，方可適用此活動。請恕併入房帳結帳者將不適用。",
  },
  filters: {
    country: "國家：",
    state: "省：",
    stateDefault: "All states",
    city: "城市：",
    cityDefault: "所有城市",
    cuisine: "料理類別：",
    cuisineDefault: "所有料理",
  },
  results: {
    halal: "清真",
    notAvailable: "此選擇不提供餐廳",
    viewmap: "顯示詳細地圖",
    makebooking: "如需預訂，請瀏覽：",
    makebookingTel: "電話預約：",
    orderOnline: "如需點餐，請瀏覽：",
    deliveryTakeaway: "提供外送和外賣",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "全新加入",
    locations: "餐廳位置",
    button: "點選後瀏覽各分店位置",
    groupCuisine: "各種料理菜系",
    close: "關閉",
    bookNow: "立即預訂",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "瀏覽與預訂日本指定餐廳（請留意，僅適用於Pocket Concierge事先預付費用之指定餐廳）",
    cuisines: "各種料理菜系",
    instruction: "如需預訂，請瀏覽：",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>本活動需於活動期間，以台灣美國運通發行之美國運通簽帳白金卡主卡身分登錄，刷卡金回饋將於成功登錄本活動後開始計算。</p><p>僅適用於收到《白金美饌‧非同凡饗》電子邀請函，並持有美國運通簽帳白金卡（不含美國運通長榮航空簽帳白金卡）之指定主卡會員。本活動將合併計算美國運通簽帳白金卡主卡及其附屬卡之合資格消費，但不含主／附屬卡會員名下之其他美國運通卡。</p><p>詳情請參閱<a href="https://go.amex/taiwangdc" target="_blank">活動細則與注意事項。</a><br>適用本活動之「全台指定餐廳」定義為該餐廳位於台灣境內。<br>適用本活動之「海外指定餐廳」定義為該餐廳位於台灣境外。<br有關優惠之詳情，請參閱您收到的《白金美饌‧非同凡饗》電子邀請函。</p><p><a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=bonustaiplat0301158&channel=IBW&locale=zh-TW" target="_blank">立即登錄</a></p><p>版權© 2023 台灣美國運通國際股份有限公司保留一切權利</p>',
  },
  platinumBanner: {
    title: "簽帳白金卡專屬網站",
    text: "探索更多美國運通簽帳白金卡優惠",
    button: "立即瀏覽",
  },
};

export default lang;
