// Packages
import { useContext } from "react";

// Context
import { Language } from "../../Language/Language";

// Components
// import Analytics from "./Analytics";

// Elements
import { Container, Disclaimer, Logo } from "./Footer.elements";

function setPath(copy, path) {
  if (typeof copy === "string" && copy.includes("%PATH%")) {
    return copy.replace(/%PATH%/, path);
  }
  return copy;
}

export default function Footer({ isJP, locale }) {
  const { footer: copy } = useContext(Language);
  return (
    <Container id="footer">
      <Disclaimer
        isJP={isJP}
        dangerouslySetInnerHTML={{
          __html: setPath(copy.disclaimer, `/${locale}/benefits/diningbenefit/`) || ""
        }}
      ></Disclaimer>
      <Logo />
      {/* <Analytics locale={locale} /> */}
    </Container>
  );
}
