const lang = {
  intro: {
    title: "Descubre los restaurantes participantes",
    paragraph:
      "<p>Como parte del beneficio  The Global Dining Credit, hemos seleccionado más de 2000+ restaurantes, curados especialmente para ti.</p>",
    tcLink:
      '<a href="%PATH%Terminos-y-Condiciones-Mexico.pdf" target="_blank" rel="noopener">Consulta detalles en Términos y Condiciones</a>',
    hotelRestaurant:
      "* Tenga en cuenta que si se aloja en un hotel, este crédito se aplicará únicamente al abonar la cuenta del restaurante directamente con el restaurante, independientemente del pago total del hotel.",
  },
  filters: {
    country: "País:",
    state: "Estado:",
    stateDefault: "All states",
    city: "Ciudad:",
    cityDefault: "Todas las ciudades",
    cuisine: "Tipo de cocina:",
    cuisineDefault: "Todo tipo de Cocina",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurantes no disponibles para esta selección",
    viewmap: "Ver en el mapa",
    makebooking: "Para reservar visite:",
    makebookingTel: "Número para reservar:",
    orderOnline: "Para ordenar visita:",
    deliveryTakeaway: "entrega / comida para llevar disponible",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "Nuevo",
    locations: "Ubicaciones",
    button: "Haga click para ubicaciones",
    groupCuisine: "Varios tipos de cocina",
    close: "Cerrar",
    bookNow: "Reserve",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Reserve en los mejores restaurantes de Japón (son elegibles los restaurantes aceptando depósito o prepago)",
    cuisines: "Varios tipos de cocina",
    instruction: "Para reservar visite:",
    button: "Visit Pocket Concierge",
  },
  platinumBanner: {
    title: "Platinum Benefits Directory",
    text: "Explore all your Card benefits across travel, entertainment and events, insurance, and more. Exclusive to Platinum Cardmembers. Terms and conditions apply.",
    button: "Visit Platinum Benefits Directory",
  },
  centurionBanner: {
    title: "Centurion Benefits Directory",
    text: "Explore all your Card benefits across travel, entertainment & events, insurance and more. Exclusive to Centurion Cardmembers.",
    button: "Visit Centurion Benefits Directory",
  },
  footer: {
    disclaimer:
      "<p>Los “restaurantes locales participantes” significa restaurantes ubicados en México y los ”restaurantes internacionales participantes” significa restaurantes ubicados fuera de México.</p><p>Para conocer más detalles, consulte los Términos y Condiciones al activar la promoción.</p><p>Para conocer más detalles de la promoción, consulte la comunicación enviada por correo electrónico titulado.</p><p>La bonificación deberá aparecer en su Estado de Cuenta bajo el concepto de “BonificacionRest” o el nombre del restaurante participante.</p><p>Copyright © 2023 American Express Company</p>",
  },
};

export default lang;
