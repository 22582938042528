// Elements
import { Filter, Label, Select } from "./Filters.elements";

export function buildSelect({ name, label, all, items, countryId, onChangeHandler, hideSelect, market }) {
  function translate(item) {
    // Fall back to "en" value...
    const f = item.translations[market] || item.translations["en"];
    return f.title;
  }
  return (
    <Filter style={{'display': hideSelect ? 'none' : ''}}>
      <Label>{label}</Label>
      <Select name={name} id={name} onChange={onChangeHandler}>
        <option value="">{all}</option>
        {items.map((item) => (
          <option key={`${countryId}-${item.id}`} value={item.id}>
            {translate(item)}
          </option>
        ))}
      </Select>
    </Filter>
  );
}
