import React from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import reportWebVitals from "./reportWebVitals";

import GlobalStyles from "./GlobalStyles";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import App from "./App";

// window.digitalData = null; // Used for AMEX tracking

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <GlobalStyles />
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
