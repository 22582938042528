// Packages
import { Suspense, useContext, useEffect, useState } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import debounce from "lodash.debounce";
import Shuffle from "shufflejs";

// Store
import {
  cityId,
  countryProps,
  cuisineId,
  filters,
  merchantsData,
  modalMerchants,
  showMerchantsModal,
  modalMerchant,
  filterHalal,
} from "../../Store/Store";

// Context
import { Language } from "../../Language/Language";

// Elements
import {
  SuspenseContainer,
  Container,
  ErrorMsg,
  Card,
  CardBody,
  CardBodyGrouped,
  CardFooter,
  Title,
  Address,
  Detail,
  Instruction,
  Button,
  ModalButton,
  ButtonDisabled,
  New,
  LocationOuter,
  LocationInner,
  LocationInfo,
  HotelRestaurantStar,
} from "./Results.elements";

import { IconCuisine, IconTakeout, IconPin, IconBell } from "./Results.icons";

import { setCardWidth } from "./Listing.helpers";

import auMerchantOverrides from "./auMerchantOverides";

function MerchantsSuspense() {
  const arr = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  return (
    <SuspenseContainer>
      {arr.map((_, index) => (
        <div key={index} className="col-6@xs col-6@sm col-4@md">
          <Card isGhost>
            <CardBody>
              <Title>A</Title>

              <Address>
                A<br />A
              </Address>

              <Detail>
                <IconPin />
                <a href="/">A</a>
              </Detail>

              <Detail>
                <IconCuisine />A
              </Detail>
            </CardBody>
            <CardFooter>
              <Button href="/">A</Button>
            </CardFooter>
          </Card>
        </div>
      ))}
    </SuspenseContainer>
  );
}

function Merchants({ filterCallback, locale }) {
  const { results: copy } = useContext(Language);
  /* eslint-disable no-unused-vars */
  const [groupedMerchants, setGroupedMerchants] =
    useRecoilState(modalMerchants);
  const [showModal, setShowModal] = useRecoilState(showMerchantsModal);
  const [filterHalalMerchants] = useRecoilState(filterHalal);

  const [groupMerchant, setGroupMerchant] = useRecoilState(modalMerchant);
  /* eslint-enable no-unused-vars */

  const { country } = useRecoilValue(filters);
  const merchants = useRecoilValue(merchantsData);
  const props = useRecoilValue(countryProps);
  const selectedCity = useRecoilValue(cityId);
  const selectedCuisine = useRecoilValue(cuisineId);

  const setShowFilterMsg = (value) => {
    filterCallback(value);
  };

  const [filtered, setFiltered] = useState([]);
  const [takeoutFlag, setTakeoutFlag] = useState(false);
  const [shuffler, setShuffler] = useState(null);

  function checkTakeoutFlag() {
    return props.isTakeoutAvailable ?? false;
  }

  useEffect(() => {
    if (!merchants || !country) return;

    if (filterHalalMerchants) {
      const f = merchants.filter((merchant) => {
        if (merchant.isHalal) {
          return merchant;
        }

        return false;
      });

      const s = f.map((m) => {
        if (m.merchants.length) {
          const filteredNestedMerchants = m.merchants.filter(
            (nestedMerchant) => nestedMerchant.isHalal
          );
          return filteredNestedMerchants.length
            ? { ...m, merchants: filteredNestedMerchants }
            : m;
        }
        return m;
      });

      setFiltered(s);
    } else {
      setFiltered(merchants);
    }
    setTakeoutFlag(checkTakeoutFlag(country));
  }, [merchants, country, filterHalalMerchants]);

  useEffect(() => {
    let debFunc = debounce(() => {
      handleResize();
    }, 50);

    handleResize();

    window.addEventListener("resize", debFunc);

    return () => {
      if (debFunc) {
        window.removeEventListener("resize", debFunc);
      }
    };
  }, []);

  useEffect(() => {
    if (merchants && merchants.length) {
      if (!shuffler) {
        addShuffler();
      } else {
        setTimeout(() => {
          setEqualHeightForShuffleItems();
        }, 0);
        setTimeout(() => {
          // shuffler.resetItems();
          shuffler.layout();
          resetResyWidgets();
        }, 500);
      }
    }
  }, [filtered, selectedCity, selectedCuisine]);

  const embedWidget = (merchantId, venueId) => {
    if (document.getElementById(merchantId)) {
      window.resyWidget.addButton(document.getElementById(merchantId), {
        venueId: venueId,
        apiKey: " 20pPy93nbRe6xwdXpdes9v5kyCJ0D5z1", // TODO Set as env var?
        replace: true,
        seats: "2",
      });
    }
  };

  const resetResyWidgets = () => {
    if (window.resyWidget) {
      merchants.forEach((merchant) => {
        if (merchant.isMerchantGroup && merchant.merchants.length) {
          merchant.merchants.forEach((groupMerchant) => {
            const { resyEnabled, resyVenueId } = groupMerchant.businessData;
            if (resyEnabled && resyVenueId) {
              embedWidget(groupMerchant.id, resyVenueId);
            }
          });
        } else {
          const { resyEnabled, resyVenueId } = merchant.businessData;
          if (resyEnabled && resyVenueId) {
            embedWidget(merchant.id, resyVenueId);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (merchants && merchants.length) {
      if (!shuffler) {
        addShuffler();
      } else {
        setTimeout(() => {
          setEqualHeightForShuffleItems();
        }, 0);
        setTimeout(() => {
          shuffler.resetItems();
        }, 500);
      }
    }
  }, [merchants, filterHalalMerchants]);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        resetResyWidgets();
        const modal = document.getElementById("modal");
        modal.setAttribute("tabindex", 0);
        modal.focus();
      }, 500);
    }
  }, [showModal]);

  useEffect(() => {
    if (shuffler && shuffler.resetItems) {
      shuffler.resetItems();
    }
  }, [shuffler]);

  useEffect(() => {
    if (shuffler && shuffler.filter) {
      // setShowFilterMsg(false);
      let filters = [];
      if (selectedCity && selectedCity.length) {
        filters.push(selectedCity);
      }
      if (selectedCuisine && selectedCuisine.length) {
        filters.push(selectedCuisine);
      }
      shuffler.filter((element) => {
        const groups = JSON.parse(element.getAttribute("data-groups"));
        if (!groups.includes("groupListing")) {
          if (
            (selectedCity && !groups.includes(selectedCity)) ||
            (selectedCuisine && !groups.includes(selectedCuisine))
          ) {
            return false;
          }
        }
        return true;
      });

      setTimeout(() => {
        setShowFilterMsg(
          document.querySelectorAll(".shuffle-item--hidden").length > 0 &&
            document.querySelectorAll(".shuffle-item").length ===
              document.querySelectorAll(".shuffle-item--hidden").length
            ? true
            : false
        );
      }, 250);
    }
  }, [selectedCity, selectedCuisine]);

  function addShuffler() {
    setShuffler(
      new Shuffle(document.getElementById("shuffleWrapper"), {
        itemSelector: ".shuffle-card",
        sizer: document.getElementById("shuffleSizer"),
        filterMode: Shuffle.FilterMode.ALL,
        speed: 275,
      })
    );
  }

  function updateShuffle() {
    if (shuffler && shuffler.update) {
      setTimeout(() => {
        setEqualHeightForShuffleItems();
      }, 0);
      setTimeout(() => {
        shuffler.update();
      }, 500);
    }
  }

  function handleResize() {
    updateShuffle();
  }

  function setEqualHeightForShuffleItems(childItemSelector) {
    const elements = Array.from(
      document.querySelectorAll("#shuffleWrapper .shuffle-card")
    );
    if (elements.length) {
      const heights = [];
      elements.forEach((el) => {
        heights.push(el.offsetHeight);
      });
      const maxHeight = Math.max(...heights);
      // console.log(maxHeight);
      elements.forEach((el) => {
        el.querySelector("div").style.height = `${maxHeight}px`;
      });
    }
  }

  function getTranslation(key, translations) {
    const market = locale.replace("-", "_");
    if (translations[market] && translations[market][key]) {
      return translations[market][key];
    }
    // Fall back to "en" value...
    return translations["en"][key];
  }

  function translate(item, translations) {
    // Fall back to "en" value...
    const market = locale.replace("-", "_");
    if (!item || !item.hasOwnProperty("translations")) {
      throw Error(`Translations object not in ${JSON.stringify(item)}`);
    }
    const f = item.translations[market] || item.translations["en"];
    return f.title;
  }

  function findMerchantGroup(id, filteredMerchants = []) {
    const groupMerchant = merchants.find((merchant) => {
      return merchant.id === id;
    });
    // console.log(groupMerchant.merchants, filteredMerchants);
    if (filteredMerchants.length) {
      const filtered = groupMerchant.merchants.filter((item) =>
        filteredMerchants.includes(item.id)
      );
      setGroupedMerchants(filtered);
    } else {
      setGroupedMerchants(groupMerchant.merchants);
    }
    setGroupMerchant(groupMerchant);
    setShowModal(true);
  }

  function isCountryBelguimOrNetherlands() {
    if (country === "BE" || country === "NL") {
      return true;
    }
    return false;
  }

  return (
    <>
      {filtered.map(
        ({
          id,
          name,
          address,
          cuisine,
          city,
          postcode,
          businessData,
          translations,
          googleMapsUrl,
          takeoutAvailable,
          onlineOnly,
          isMerchantGroup,
          merchants,
          isNew,
          isHalal,
          allMerchantsNew,
        }) => {
          const hasMerchants = isMerchantGroup && merchants.length;
          const filteredMerchants = [];
          const cuisines = [];
          const postcodeFirst = isCountryBelguimOrNetherlands();

          if (hasMerchants) {
            merchants.forEach((item) => {
              let matchCity = false;
              let matchCuisine = false;
              let newCuisine = false;
              if (
                (selectedCity && item.city.id === selectedCity) ||
                !selectedCity
              ) {
                matchCity = true;
              }
              if (cuisines.length) {
                for (let i = 0; i < cuisines.length; i += 1) {
                  if (cuisines[i].id !== item.cuisine.id) {
                    newCuisine = true;
                    break;
                  }
                }
              } else {
                newCuisine = true;
              }
              if (newCuisine) {
                cuisines.push(item.cuisine);
              }
              if (
                (selectedCuisine && item.cuisine.id === selectedCuisine) ||
                !selectedCuisine
              ) {
                matchCuisine = true;
              }
              if (matchCity && matchCuisine) {
                filteredMerchants.push(item.id);
              }
            });

            // console.log(cuisines);
          }

          const hoistedMerchant =
            filteredMerchants.length === 1
              ? merchants.find(
                  (merchant) => merchant.id === filteredMerchants[0]
                )
              : null;

          // override parent item params
          if (hoistedMerchant) {
            name = hoistedMerchant.name;
            address = hoistedMerchant.address;
            cuisine = hoistedMerchant.cuisine;
            city = hoistedMerchant.city;
            postcode = hoistedMerchant.postcode;
            businessData = hoistedMerchant.businessData;
            translations = hoistedMerchant.translations;
            googleMapsUrl = hoistedMerchant.googleMapsUrl;
            takeoutAvailable = hoistedMerchant.takeoutAvailable;
            onlineOnly = hoistedMerchant.onlineOnly;
          }

          const { website, phone, openingSoon, isInHotel } = businessData;
          const hasWebsite = website && website.length;
          const hasPhone = phone && phone.length;
          const hasHalal = isHalal === true;
          const hotelRestaurant = isInHotel === true;
          const hasAuOverride =
            country === "AU" && auMerchantOverrides.includes(id);

          return (
            <div
              key={id}
              className="col-6@xs col-6@sm col-4@md shuffle-card"
              data-groups={`["${city ? city.id : "no-city"}","${
                cuisine ? cuisine.id : "no-cuisine"
              }","${
                hasMerchants && filteredMerchants.length
                  ? "groupListing"
                  : "not-grouped"
              }"]`}
            >
              {(!hasMerchants || hoistedMerchant) && (
                <Card>
                  <CardBody>
                    <Title>
                      {businessData.isNew && <New>{copy.new}</New>}
                      {getTranslation("name", translations)}
                      {hotelRestaurant && (
                        <HotelRestaurantStar>*</HotelRestaurantStar>
                      )}
                    </Title>

                    {!onlineOnly && city && !postcodeFirst && (
                      <Address>
                        {getTranslation("address", translations)}
                        <br />
                        {translate(city)}
                        <br />
                        {getTranslation("postcode", translations)}
                      </Address>
                    )}

                    {!onlineOnly && city && postcodeFirst && (
                      <Address>
                        {getTranslation("address", translations)}
                        <br />
                        {getTranslation("postcode", translations)}
                        <br />
                        {translate(city)}
                      </Address>
                    )}

                    {onlineOnly && !address && (
                      <Address>{copy.onlineOnly}</Address>
                    )}

                    {onlineOnly && address && !city && (
                      <Address>
                        {getTranslation("address", translations)}
                      </Address>
                    )}

                    {onlineOnly && address && city && (
                      <Address>
                        {getTranslation("address", translations)}
                        <br />
                        {translate(city)}
                      </Address>
                    )}

                    {hasAuOverride && (
                      <div>Restaurant participation temporarily suspended</div>
                    )}

                    {!hasAuOverride && !onlineOnly && googleMapsUrl && (
                      <Detail>
                        <IconPin />
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={googleMapsUrl}
                        >
                          {copy.viewmap}
                        </a>
                      </Detail>
                    )}

                    {!hasAuOverride && cuisine && (
                      <Detail>
                        <IconCuisine />
                        {translate(cuisine)}
                      </Detail>
                    )}

                    {!hasAuOverride && takeoutFlag && takeoutAvailable && (
                      <Detail>
                        <IconTakeout />
                        {copy[takeoutAvailable]}
                      </Detail>
                    )}

                    {hasHalal && <Detail>{copy.halal}</Detail>}
                  </CardBody>

                  {hasAuOverride && <CardFooter />}

                  {!hasAuOverride && (
                    <CardFooter>
                      {businessData.resyEnabled && businessData.resyVenueId ? (
                        <div id={id}></div>
                      ) : (
                        <>
                          {hasWebsite && (
                            <>
                              {openingSoon && (
                                <Instruction marginBottom={2} weight={500}>
                                  <IconBell />
                                  <span className={"has-icon"}>
                                    {copy.openingSoon}
                                  </span>
                                </Instruction>
                              )}
                              {!openingSoon && (
                                <Instruction>
                                  <IconBell />
                                  <span className={"has-icon"}>
                                    {onlineOnly
                                      ? copy.orderOnline
                                      : copy.makebooking}
                                  </span>
                                </Instruction>
                              )}
                              {openingSoon ? (
                                <ButtonDisabled>
                                  {website.split("/")[2].replace("www.", "")}
                                </ButtonDisabled>
                              ) : (
                                <Button
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={website}
                                >
                                  {website.split("/")[2].replace("www.", "")}
                                </Button>
                              )}
                            </>
                          )}

                          {!hasWebsite && hasPhone && (
                            <>
                              {openingSoon && (
                                <Instruction marginBottom={2} weight={500}>
                                  Opening soon
                                </Instruction>
                              )}
                              <Instruction>
                                <span>{copy.makebookingTel}</span>
                              </Instruction>
                              {openingSoon ? (
                                <ButtonDisabled>
                                  {website.split("/")[2].replace("www.", "")}
                                </ButtonDisabled>
                              ) : (
                                <Button
                                  rel="noopener noreferrer"
                                  href={`tel:${phone}`}
                                >
                                  {phone}
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </CardFooter>
                  )}
                </Card>
              )}

              {hasMerchants && !hoistedMerchant && (
                <Card location>
                  <CardBodyGrouped>
                    <Title>
                      {allMerchantsNew && <New>{copy.new}</New>}
                      {getTranslation("name", translations)}
                    </Title>
                    <LocationOuter>
                      <LocationInner>
                        <LocationInfo>
                          <Detail>
                            <IconPin /> {filteredMerchants.length}{" "}
                            {copy.locations}
                          </Detail>
                          <Detail>
                            <IconCuisine />{" "}
                            {cuisines.length > 1 ? (
                              <>{copy.groupCuisine}</>
                            ) : (
                              <>{translate(cuisines[0])}</>
                            )}
                          </Detail>
                          {hasHalal && <Detail>{copy.halal}</Detail>}
                        </LocationInfo>
                        {hasAuOverride && (
                          <div style={{ marginTop: "1em" }}>
                            Restaurant participation temporarily suspended
                          </div>
                        )}
                      </LocationInner>
                    </LocationOuter>
                  </CardBodyGrouped>

                  <CardFooter>
                    <ModalButton
                      onClick={() => findMerchantGroup(id, filteredMerchants)}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13 || e.keyCode === 32) {
                          findMerchantGroup(id, filteredMerchants);
                          e.stopPropagation();
                        }
                      }}
                    >
                      {copy.button} ({filteredMerchants.length})
                    </ModalButton>
                  </CardFooter>
                </Card>
              )}
            </div>
          );
        }
      )}
    </>
  );
}

export default function Results({ locale, country, filterHalalMerchants }) {
  const { results: copy } = useContext(Language);
  const [showFilterMsg, setShowFilterMsg] = useState(false);
  const filterCallback = (showMsg) => {
    setShowFilterMsg(showMsg);
  };

  useEffect(() => {
    setCardWidth();
  }, []);

  return (
    <Container>
      <ErrorMsg style={{ display: showFilterMsg ? "" : "none" }}>
        {copy.notAvailable}
      </ErrorMsg>
      <div id="shuffleWrapper" className="row">
        <Suspense fallback={<MerchantsSuspense />}>
          <Merchants
            filterCallback={filterCallback}
            locale={locale}
            country={country}
            filterHalalMerchants={filterHalalMerchants}
          />
        </Suspense>
        <div
          id="shuffleSizer"
          className="col-1@xs col-1@sm col-1@md col-1@lg "
        ></div>
      </div>
    </Container>
  );
}
