const lang = {
  intro: {
    title: "Explorez votre avantage restaurant à travers le monde.",
    paragraph:
      "<p>Dans le cadre de votre avantage restaurant American Express, nous avons sélectionné plus de 2000+ restaurants à explorer dans %COUNT% pays. Alors, qu’il s’agisse d’un nouveau restaurant en Belgique, au Luxembourg ou à l’autre bout du monde, où allez-vous dîner ?</p>",
    tcLink:
      '<a href="%PATH%Les-Conditions-Generales-Belgium-Luxembourg.pdf" target="_blank" rel="noopener">Voir tous les détails du bénéfice et les conditions générales</a>',
    hotelRestaurant:
      "* Please note that if you’re staying in a hotel, this credit will be applied only when settling the restaurant bill directly with the restaurant, separate from the overall hotel payment.",
  },
  filters: {
    country: "Pays :",
    state: "Etat :",
    stateDefault: "All states",
    city: "Ville :",
    cityDefault: "All cities",
    cuisine: "Cuisine :",
    cuisineDefault: "All cuisines",
  },
  results: {
    halal: "Halal",
    notAvailable: "Restaurants non disponibles pour cette sélection",
    viewmap: "Voir sur la carte",
    makebooking: "Pour réserver, rendez-vous sur :",
    makebookingTel: "Pour réserver par téléphone :",
    orderOnline: "Pour commander, veuillez consulter le site :",
    deliveryTakeaway: "Emporter / livraison disponible",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "Nouveau",
    locations: "Localisations",
    button: "Cliquez pour voir les localisations",
    groupCuisine: "Différents types de cuisines",
    close: "Fermer",
    bookNow: "Réservez maintenant",
  },
  pocket: {
    title: "Explore 800+ restaurants and book directly with Pocket Concierge",
    button: "Visit Pocket Concierge",
  },
  pocketCard: {
    title: "Pocket Concierge",
    description:
      "Découvrez et réservez auprès des meilleurs restaurants au Japon (seuls les restaurants qui acceptent les prépaiements sont éligibles)",
    cuisines: "Différents types de cuisines",
    instruction: "Pour réserver, rendez-vous sur :",
    button: "Visit Pocket Concierge",
  },
  footer: {
    disclaimer:
      '<p>Inscription obligatoire et offre réservée aux Titulaires des Cartes Centurion et Platinum. Platinum, cliquez <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=beplatbonus070122&channel=IBW&locale=fr-BE"> ici</a>. Centurion, cliquez <a href="https://global.americanexpress.com/enrollments/oletenroll?campaignId=becentbonus070122&channel=IBW&locale=en-BE"> ici</a>.</p><p>De plus amples informations et conditions générales sont disponibles lors de l\'inscription à l\'avantage.</p><p>Restaurants participants à l’étranger comprend tous les restaurants participants en dehors de la Belgique et du Grand Duché de Luxembourg.</p><p>Important: les acomptes versés pour la réservation des restaurants ou les dîners entièrement ou partiellement prépayés ne font pas partie de l’Avantage restaurant et ne sont pas remboursés. En effet, ils sont souvent gérés par un agrégateur tiers et non pas par le restaurant lui-même, donc non reconnu.</p><p>Copyright © 2023 American Express Company</p>',
  },
};

export default lang;
