// Packages
// import { useContext } from "react";

// Context
// import { Language } from "../../Language/Language";

// Elements
import { Container, Title, Paragraph } from "./Copy.elements";

export default function Copy() {
  // const { intro } = useContext(Language);
  // const { title, paragraph } = intro;

  return (
    <>
      <Container>
        <Title>404 Error. </Title>
        <Paragraph>
          The website you were trying to reach couldn't be found, please go
          back.
        </Paragraph>
      </Container>
    </>
  );
}
