// Packages
import { useState, useEffect } from "react";
import { useNavigate/*, useParams*/ } from "react-router-dom";

// Elements
import { Container, Select } from "./LangSelect.elements";

export default function LangSelect({ locale }) {
  const navigate = useNavigate();

  const [lang, setLang] = useState("EN");
  const [country, setCountry] = useState("GB");
  const [langs, setLangs] = useState([]);

  const parings = {
    CA: ["EN", "FR"],
    HK: ["EN", "ZH"],
    // TW: ["EN", "ZH"],
    BE: ["NL", "FR"],
    TH: ["EN", "TH"]
  };

  useEffect(() => {
    const split = locale.toUpperCase().split("-");

    const lang = split[0];
    const country = split[1];

    setLang(lang);
    setCountry(country);

    country in parings ? setLangs(parings[country]) : setLangs(null);
  }, [locale]);

  function onChangeHandler({ target: { value } }) {
    const locale = `${value}-${country}`.toLowerCase();

    // Exception for ca-fr, which should use a custom URL
    if (country === 'CA' && value === 'FR') {
      navigate(`/fr-ca/avantages/avantagepourrestaurants`);
    } else {
      navigate(`/${locale}/benefits/diningbenefit`);
    }
  }

  return (
    <Container>
      {langs && (
        <Select
          name="langSelect"
          id="langSelect"
          onChange={onChangeHandler}
          value={lang}
        >
          {langs.map(l => (
            <option key={l} value={l}>
              {l}
            </option>
          ))}
        </Select>
      )}
    </Container>
  );
}
