const auMerchantOverrides = [
  'be71e1f1-ec71-43fb-a1f0-073a2f9f3106', // Margaret
  '7d3e20bd-850d-4da5-8fc6-6139d5acab57', // Rockpool (group)
  '8dc1a691-4541-4a57-9dd1-dc3371988780', // Rockpool
  '96ba8d23-2bf6-460a-b18e-df94ade42a7d', // Rockpool
  'f2152656-a30e-4238-a758-a989251bbc97', // Rockpool
  'ba592a06-7fa7-4cd5-8925-5176792ee67c', // Rosetta
  '782d89b3-5a46-4a80-9bc9-524dbe2666c2', // Spice Temple (group)
  '398a332d-72c3-4927-9ffe-35b3bbdc1f02', // Spice Temple
  '9ce4e83f-3661-47f7-9ce5-9b66eda2417c', // Spice Temple
  // --------
  // '233e141a-17b7-4784-b809-c66edecfb900', // chin chin nsw
  // '5283821e-914e-43c2-b03b-8f0badcd02aa', // chin chin melbourne
  // '1469a8b2-336e-4ef8-a3d6-5e7b57e74ff8', // grill americano
  // '9c399f40-ee69-4144-b31f-4e688b9283c2', // kisume
  // 'e0d9106c-3d00-47da-88b6-f891d0c3b059', // lillian brass
  // '48a4ac22-8385-4073-8c5d-b3aeb65fa717', // Society
  // 'dce92389-52b7-4c44-b6e3-67ed1dfe1f6e', // Yakimono
  // '32de40d5-1d19-4033-a4dc-643465345d45', // Cumulus
  // '81b45590-d7d6-4ae0-8eff-781401351434', // Cutler
  // 'dc286d1d-7f9a-41df-9c29-5db19530bca4', // Gimlet at Cavendish House
  // 'bfd7d828-afc6-4979-8faf-c010127358cb', // Supernormal
];

export default auMerchantOverrides;
