const lang = {
  intro: {
    title: "ダイニングの世界を探求する",
    paragraph:
      "<p>プラチナ・カードの特典「グローバル・ダイニング・キャッシュバック」をご利用いただけるレストランを、国内外約20か国2,000以上のレストランから検索いただけます。いつもの町での新しいダイニング体験から、まだ見ぬ世界のダイニングまで、プラチナ・カード®でお楽しみください。<BR>オンライン・サービスまたはアプリにログインの上、アメックス・オファー内の「グローバル・ダイニング・キャッシュバック」にご登録ください。</p>",
    tcLink:
      '<a href="https://www.americanexpress.com/ja-jp/benefits/lifestyle/dining/global-dining-cashback/" target="_blank" rel="noopener">特典の詳細と規約を確認する</a>',
    hotelRestaurant:
      "* ポケットコンシェルジュは、アメリカン・エキスプレスのグループ会社です。",
  },
  filters: {
    country: "国：",
    state: "State:",
    stateDefault: "すべての州",
    city: "市：",
    cityDefault: "すべての都市",
    cuisine: "料理：",
    cuisineDefault: "すべての料理",
  },
  results: {
    halal: "Halal",
    notAvailable: "",
    viewmap: "アクセス",
    makebooking: "リンク先のサイトよりご予約ください",
    makebookingTel:
      "下記お電話番号よりご予約いただけます　※日本語対応でない可能性がございます。",
    orderOnline: "To order please visit:",
    deliveryTakeaway: "Delivery/Take-Away available",
    deliveryProvidoor: "Delivery available via Providoor",
    openingSoon: "Opening soon",
    onlineOnly: "Online Only",
    new: "NEW",
    locations: "レストラン",
    button: "レストランを見る",
    groupCuisine: "Various cuisines",
    close: "閉じる",
    bookNow: "予約する",
  },
  pocket: {
    title: "800以上のレストランを探索し、ポケットコンシェルジュで直接予約する",
    button: "ポケットコンシェルジュにアクセス",
  },
  pocketCard: {
    title: "ポケットコンシェルジュ",
    description:
      "ポケットコンシェルジュのウェブサイトより約1,000以上のレストランをご予約いただけます。<br>＊ポケットコンシェルジュは、アメリカン・エキスプレスのダイニングサービスです。<br>＊ポケットコンシェルジュを通して決済いただいた場合のみキャッシュバックの対象です。",
    cuisines: "様々なジャンルの料理",
    instruction: "リンク先のサイトよりご予約ください",
    button: "レストランを検索",
  },
  platinumBanner: {
    title: "プラチナ・カード® ウェブサイト",
    text: "プラチナ・カード会員様がご利用いただける特典やサービスをはじめ、期間限定キャンペーンなどの旬の情報を掲載しています。",
    button: "プラチナ・カードのサービスを見る",
  },
  footer: {
    disclaimer:
      "<p>特典をご利用いただくには、サービスへの登録が必要です。オンライン・サービスまたはアプリにログインの上、アメックス・オファー内の「グローバル・ダイニング・キャッシュバック」にご登録ください。特典の詳細と利用規約はご登録時に同ページよりご確認いただけます。</p>",
  },
};

export default lang;
